import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "../../../styles/components/Slices/FeatureMatisse.scss";

const FeatureMatisse = ({ encordProducts, cardIcons, slice }) => {
  const [activeProduct, setActiveProduct] = useState(0);
  const [target, setTarget] = useState(null);
  const ismobile = useMediaQuery({ maxWidth: 630 });
  const MobileScreenLabel = "Encord Workflow ";

  useEffect(() => {
    // Increment activeProduct to move to the next index
    const interval = setInterval(() => {
      setActiveProduct((prevIndex) =>
        prevIndex === slice.items.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [activeProduct, slice.items.length]);

  return (
    <article
      className={`py-10 lg:py-20  ${slice.primary?.theme == "dark" ? "matisse_bg" : "bg-white"}`}
    >
      <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <section
          className={`${slice.primary?.theme == "dark" ? "matisse_border" : "bg-white border-gray-1600"} border my-5 sm:my-10  px-4 py-6 sm:py-14 gap-6 lg:px-10 rounded-2xl sm:rounded-2.5xl lg:mx-11`}
        >
          <p
            className={`text-xs font-semibold sm:font-medium uppercase lg:text-base text-purple-1500  ${slice.primary?.theme == "dark" ? " text-purple-350" : "text-purple-1500"} tracking-widest_1`}
          >
            {slice?.primary?.caption_title?.text}
          </p>
          <p
            className={`mt-4  ${slice.primary?.theme == "dark" ? "text-white" : "text-purple-1100"} text-2xl sm:text-3.25xl font-manrope tracking-[-1.2px] sm:tracking-[-1.8px] font-semibold`}
          >
            {slice?.primary?.title?.text}
          </p>
          <p
            className={`mt-5 text-sm sm:text-base ${slice.primary?.theme == "dark" ? "text-white opacity-60" : " text-gray-600"} tracking-tighter_3`}
          >
            {slice?.primary?.description?.text}
          </p>
          <section
            className={`flex flex-col items-center xl:items-start mt-6 ${slice.primary?.theme == "dark" ? "matisse_inner_border " : " lg:bg-white bg-gray-2800"} lg:flex-row rounded-xl sm:rounded-2xl border overflow-hidden lg:p-0 p-4 sm:p-5 `}
          >
            <div
              className={`lg:max-w-[458px] text-left ${slice.primary?.theme == "dark" ? "text-gray-2200" : " text-gray-600 bg-gray-2800"}`}
            >
              {ismobile ? (
                <h2
                  className={`text-xs tracking-widest_1 opacity-50 font-medium uppercase p-4 sm:p-5`}
                >
                  {MobileScreenLabel}
                </h2>
              ) : (
                <h2
                  className={`text-xs  tracking-widest_1 opacity-50 font-medium uppercase p-4 sm:p-5`}
                >
                  {slice?.primary?.card_sub_title?.text}
                </h2>
              )}
              <div className="h-full">
              <div className="flex flex-col lg:max-w-md md:justify-start">
                {slice?.items?.map(
                  ({ title, description, image, icon, hover_icon }, index) => {
                    return (
                      <>
                        <div
                          className={` p-4 group sm:p-5 gap-2.5 duration-200 ease-linear overflow-hidden ${
                            activeProduct !== index
                              ? ""
                              : `matisse_hover_bg lg:rounded-none rounded-xl`
                          }`}
                          key={index}
                          //   onClick={() => {
                          //     isDesktop
                          //       ? cta_link?.url && navigate(cta_link?.url)
                          //       : setActiveProduct(index);
                          //   }}
                          onMouseEnter={() => {
                            setTarget(title?.text);
                            setActiveProduct(index);
                          }}
                          onMouseLeave={() => {
                            setTarget(null);
                            setActiveProduct(index);
                          }}
                        >
                          <div className="">
                            <div className="flex flex-col gap-2.5">
                              <div>
                                {activeProduct == index ? (
                                  <img
                                    src={hover_icon?.url}
                                    alt="img"
                                  />
                                ) : (
                                  <img
                                    src={icon?.url}
                                    alt="icon"
                                  />
                                )}
                              </div>

                              <div className="w-full gap-2.5">
                                <div className="flex">
                                  <h3
                                    className={`text-xl font-semibold ${activeProduct != index && slice.primary?.theme != "dark" ? "text-gray-600" : activeProduct != index && slice.primary?.theme == "dark" && "text-white opacity-60"}   ${activeProduct == index && slice.primary?.theme != "dark" ? "text-purple-1500" : activeProduct == index && slice.primary?.theme == "dark" && "text-purple-350"}  font-manrope tracking-tightest`}
                                  >
                                    {title?.text}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p
                              className={`font-inter text-sm sm:text-base tracking-tighter_3 ${activeProduct != index && slice.primary?.theme != "dark" ? "text-gray-1200" : activeProduct != index && slice.primary?.theme == "dark" && "text-white opacity-40"}   ${activeProduct == index && slice.primary?.theme != "dark" ? "text-gray-2500" : activeProduct == index && slice.primary?.theme == "dark" && "text-gray-2200"} `}
                            >
                              {description?.text}
                            </p>
                            <div
                              className={` lg:hidden pt-5 ${
                                activeProduct == index
                                  ? "block lg:block"
                                  : " hidden lg:block"
                              }`}
                            >
                              {encordProducts?.items[activeProduct]
                                ?.product_video?.url ? (
                                <div className="justify-center flex duration-[2s] ease-in">
                                  {/* <Video
                              src={product_video?.url}
                              classes="rounded-2xl border-[0.3px] w-full h-auto border-gray-1600"
                              posterImage={posters[activeProduct]}
                            /> */}
                                </div>
                              ) : (
                                <img
                                  src={image?.url}
                                  loading="lazy"
                                  alt="img"
                                  className={`rounded-2xl border-[0.3px] border-gray-1600`}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
              </div>
            </div>
            {slice?.items[activeProduct]?.product_video?.url ? (
              <div className="justify-center xl:px-7.5 py-10 lg:px-5 p-14  duration-[1s] ease-in lg:flex hidden justify-self-center m-auto">
                {/* <Video
            src={encordProducts?.items[activeProduct]?.product_video?.url}
            classes="max-w-[450px] xl:max-w-[614px] xl:max-h-[540px] h-full flex-shrink-0 rounded-2xl mx-auto w-auto lg:block hidden"
            posterImage={posters[activeProduct]}
          /> */}
              </div>
            ) : (
              <img
                src={slice?.items[activeProduct]?.image?.url}
                width={0}
                height={0}
                loading="lazy"
                alt="img"
                className={`max-w-[450px] xl:max-w-full xl:flex-1 max-h-[582px] lg:mx-auto xl:mx-0  h-full flex-shrink-0 w-auto lg:block hidden ${slice.primary?.theme != "dark" ? "rounded-2xl": "matisse_hover_bg rounded-2xl lg:rounded-tl-none lg:rounded-bl-none lg:rounded-tr-2xl lg:br-2xl"}`}
              />
            )}
          </section>
        </section>
      </section>
    </article>
  );
};
export default FeatureMatisse;
