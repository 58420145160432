import React, { useEffect } from "react";
import "../styles/additional-styles/ctaSideBlog.scss";
import { Link } from "gatsby-link";
import bg from "../assets/side_cta_bg.svg";
import { Formik } from "formik";
import LeadFormWithAPI from "../components/LeadFormWithAPI";

const CtaSide = ({
  imageSrc,
  CtaImage,
  title,
  description,
  btnText,
  btnLink,
  btnColor = "text-white",
  btnBgColor = "btn_cta_blog gradient_btn",
  cardBgColor = "cta_card_bg",
  Cardbg,
  titleText = "text-purple-1100",
  titleClass,
  location,
  isDemo,
  flexGap = "gap-y-3",
  containerClasses,
}) => {
  return (
    <section
      className={`${cardBgColor} ${Cardbg} relative overflow-hidden px-6 lg:px-3 xl:px-5 mt-5 pt-10 pb-8 lg:py-5 xl:py-6 flex flex-col max-w-sm lg:max-w-[250px] mx-auto ${flexGap} ${containerClasses}`}
    >
      {imageSrc ? (
        <img
          target="_blank"
          src={imageSrc}
          alt="cta banner"
          className="z-20 w-6 h-6"
          width={0}
          height={0}
        />
      ) : (
        CtaImage && (
          <img
            target="_blank"
            src={CtaImage}
            alt="cta banner"
            className="w-full h-full bg-transparent"
            width={0}
            height={0}
          />
        )
      )}
      {title && (
        <h4
          className={`xl:text-xl ${titleClass} lg:text-base text-2xl tracking-tighter_1 !leading-6 font-semibold ${titleText} xl:pr-2`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {description && (
        <p className="font-medium text-black xl:text-sm lg:text-xs">
          {description}
        </p>
      )}

      {isDemo ? (
        <LeadFormWithAPI
          btnText={btnText}
          btnClass="!text-xs !bg-white !text-purple-1500 hover:!text-purple-1800 !py-2 !w-full tracking-[-0.45px]"
          inputClass="!py-2 !text-xs !mx-auto w-full"
          inputPlaceholder="Your work email"
          formClass="max-w-[180px] mx-auto"
          rotatingIcon="border-purple-1500"
          errorClass="text-2xs  !text-red-300"
          location={location}
          isFullWidth
        />
      ) : (
        btnLink && (
          <Link
            target="_blank"
            className={`${btnColor} ${btnBgColor}  cursor-pointer mt-2 max-w-[180px] mx-auto w-full`}
            to={btnLink}
          >
            {btnText}
          </Link>
        )
      )}
    </section>
  );
};

export default CtaSide;
