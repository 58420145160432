import React from "react";
import "swiper/css";
import CustomButton from "../../CustomButton";
import RightToLeftArrow from "../../../assets/icons/DiagramMonetIcons/RightToLeft";
import { LeftToRightArrow } from "../../../assets/icons/DiagramMonetIcons/LeftToRightArrow";

const NextArrow = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.67251 9.66797L12.9575 9.66797L6.80161 15.0117C6.70318 15.0979 6.76294 15.2578 6.89302 15.2578L8.44868 15.2578C8.51724 15.2578 8.58228 15.2332 8.63325 15.1893L15.276 9.42539C15.3369 9.37265 15.3857 9.30745 15.4191 9.23421C15.4525 9.16097 15.4698 9.08139 15.4698 9.00088C15.4698 8.92036 15.4525 8.84079 15.4191 8.76755C15.3857 8.6943 15.3369 8.62911 15.276 8.57637L8.59458 2.77734C8.56821 2.75449 8.53657 2.74219 8.50318 2.74219L6.89478 2.74219C6.7647 2.74219 6.70493 2.90391 6.80337 2.98828L12.9575 8.33203L2.67251 8.33203C2.59517 8.33203 2.53189 8.39531 2.53189 8.47266L2.53189 9.52734C2.53189 9.60469 2.59517 9.66797 2.67251 9.66797Z"
      fill="#434343"
    />
  </svg>
);

const DiagramMonetCard = ({ data, styleClass, showArrow }) => {
  const { title, icon, cta_text, cta_link, result_caption, result_label } =
    data;

  const getCircleColor = () => {
    switch (result_caption?.text) {
      case "Index":
        return "bg-pink-100";
      case "Annotate":
        return "bg-purple-350";
      case "Active":
        return "bg-[#66C526]";
      default:
        return "bg-yellow-200";
    }
  };

  return (
    <div
      className={`lg:max-w-[300px]  xl:max-h-[160px] group hover:bg-white bg-white lg:bg-[#f6f5fb] pt-[10px] pb-[18px] px-[14px] flex gap-3.5 flex-col justify-between rounded-xl z-10 ${styleClass}`}
    >
      <div className="flex flex-col relative justify-center gap-1.5">
        <div className="flex items-center justify-end gap-1 h-[14px]">
          <div className={`w-1 h-1 rounded-full ${getCircleColor()}`} />
          <p className="inline text-sm font-semibold leading-4 text-gray-600 group-hover:text-gray-2500 font-manrope tracking-widest_1">
            {result_caption?.text}
          </p>
        </div>

        <div className="bg-purple-1500 px-3.5 py-1 pl-1.5 pr-3 rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-[4px] text-white inline-flex gap-2 text-sm font-medium leading-4 font-inter tracking-[-0.7px] max-w-fit mt-1">
          <img
            src={icon?.url}
            loading="lazy"
            alt={icon?.url ? icon?.url : "Avatar"}
          />
          <p className="inline text-xs text-white">{result_label?.text}</p>
        </div>

        <p className="inline text-lg font-medium leading-7 group-hover:text-purple-1100 text-gray-2700 font-inter tracking-[-0.45px] ">
          {title?.text}
        </p>
      </div>
      {showArrow === "index2" && (
        <RightToLeftArrow styleClass="absolute bottom-[60px] xl:bottom-[20px] lg:left-[230px] xl:left-[266px] hidden lg:block" />
      )}
      {showArrow === "index0" && (
        <LeftToRightArrow styleClass="absolute top-[54px] lg:left-[66px] xl:left-[105px] hidden lg:block" />
      )}

      <CustomButton
        text={cta_text?.text}
        link={cta_link?.url}
        icon={<NextArrow />}
        buttonClass="bg-gray-1600 hover:text-gray-2700 hover:bg-gray-1500 text-gray-2500 lg:text-nowrap"
        direction="right"
      />
    </div>
  );
};

export default DiagramMonetCard;
