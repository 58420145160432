import React from "react";
import profile from "../../../assets/slices/testiProfile.svg";
import "../../../styles/components/Slices/TestimonialMonet.scss"

import TestimonialMonetCard from "../../Pieces/TestimonialMonetCard";

const TestimonialMonet = ({slice}) => {
  return (
    <>
      <article
        className={`relative py-10 lg:py-20 overflow-hidden  ${slice?.primary?.theme==="Dark" ? "bg-[#250038] monet_bg  my-10 lg:my-20 " : "bg-[#FAFAFA]"}`}
      >
        <TestimonialMonetCard data={slice}/>
      </article>
    </>
  );
};

export default TestimonialMonet;
