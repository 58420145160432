import React, { useContext, useState } from "react";
import ModalContext from "../../../Contexts/openModal";
import Farrow from "../../../assets/icons/NewAnnotate/Farrow";
import "../../../styles/components/Slices/heroKusama.scss";
import { SingleEmailForm } from "../../Pieces/SingleEmailForm";
import VideoComponent from "../../VideoComponent";
import DemoModal from "../../DemoModal";
import CustomButton from "../../CustomButton";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";
import bg_video from "../../../assets/new-home-page/bg_graphic_flow.webm";
import graphicPoster from "../../../assets/new-home-page/bg_graphics_poster.jpg";

const HeroKusama = ({ slice }) => {
  const { toggleModal, setEmail } = useContext(ModalContext);

  const imageUrl = slice?.primary?.image?.url;
  return (
    <>
      <article className="relative">
        <VideoComponent
          autoPlay
          poster={graphicPoster}
          className="absolute left-0 right-0 z-0 [mix-blend-mode:darken] top-24 lg:top-2 sm:top-16"
        >
          <source
            className="row-start-1 row-end-4"
            src={bg_video}
            type="video/webm"
          />
        </VideoComponent>
        <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
          <article className="relative py-10 lg:py-20 lg:mx-11 ">
            <section className={`flex flex-col lg:gap-10 max-w-7xl mx-auto `}>
              <section
                className={`flex flex-col justify-center sm:gap-7 gap-5 items-center`}
              >
                {slice?.primary?.banner_text?.text &&
                slice?.primary?.banner_link?.url ? (
                  <a
                    href={slice?.primary?.banner_link?.url}
                    target="_blank"
                    className="bg_text flex gap-2 text-sm sm:text-base font-medium leading-5 font-inter tracking-[-0.7px] items-center rounded-lg "
                  >
                    <p
                      className={`text-purple-1500 tracking-[-0.6px] sm:tracking-[-0.7px]`}
                    >
                      New
                    </p>
                    <p className="text-gray-600">
                      {slice?.primary?.banner_text.text}
                    </p>
                    <Farrow styleClass="sm:w-auto w-5" />
                  </a>
                ) : (
                  slice?.primary?.banner_text?.text  && 
                  <div className="bg_text flex gap-2 text-sm sm:text-base font-medium leading-5 font-inter tracking-[-0.7px] items-center rounded-lg ">
                    <p
                      className={`text-purple-1500 tracking-[-0.6px] sm:tracking-[-0.7px]`}
                    >
                      New
                    </p>
                    <p className="text-gray-600">
                      {slice?.primary?.banner_text.text}
                    </p>
                    <Farrow styleClass="sm:w-auto w-5" />
                  </div>
                )}

                <div
                  className={`text-3.25xl sm:text-[60px] font-manrope leading-[40px] text-purple-1100 sm:leading-[68px] font-semibold tracking-[-1.8px] lg:max-w-[740px] sm:tracking-[-3px] strong_color_style text-center`}
                  dangerouslySetInnerHTML={{
                    __html: slice?.primary?.hero_title?.html,
                  }}
                />

                <p
                  className={`sm:text-lg text-base leading-6 sm:leading-7 text-gray-2500 font-normal tracking-tight_1 font-inter max-w-[792px] text-center`}
                >
                  {slice?.primary?.descriptor_text?.text}
                </p>
                {slice?.primary?.show_email_cta ? (
                  <SingleEmailForm2
                    openModal={toggleModal}
                    setEmail={setEmail}
                    formHsClass={slice?.primary?.hs_class?.text}
                    formId={slice?.primary?.hs_id?.text}
                    buttonText={slice?.primary?.cta_title?.text}
                  />
                ) : (
                  <CustomButton
                    onClick={toggleModal}
                    buttonClass={`bg-purple-1500 hover:bg-purple-1800 text-white`}
                    text={slice?.primary?.cta_title?.text}
                  />
                )}
              </section>

              {slice?.primary?.video_link?.url ? (
                <div className="items-center p-3 mx-auto sm:p-7 bg-gray-1500 rounded-xl">
                  <VideoComponent
                    loop
                    autoPlay
                    muted
                    className="w-full h-auto mx-auto rounded-2xl"
                  >
                    <source
                      src={slice?.primary?.video_link?.url}
                      type="video/mp4"
                    />
                  </VideoComponent>
                </div>
              ) : imageUrl ? (
                <div className="items-center p-3 mx-auto sm:p-7 bg-gray-1500 rounded-xl">
                  <img
                    src={imageUrl}
                    alt="hero kusama image"
                    className="sm:rounded-[20px] rounded-lg"
                  />
                </div>
              ) : null}
            </section>
          </article>
        </section>
      </article>
    </>
  );
};
export default HeroKusama;
