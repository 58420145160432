import React, { useContext } from "react";
import LogoMarquee from "../../LogoMarquee";
import CustomButton from "../../CustomButton";
import "../../../styles/components/Slices/heroMonet.scss";
import ModalContext from "../../../Contexts/openModal";

const HeroMonet = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  const isAlignCenter = slice?.primary?.heading_section_position === "Center";
  const isEmpty = slice?.primary?.trusted_by_logo_alignment === "empty";
  const leftBottom =
    slice?.primary?.trusted_by_logo_alignment === "left bottom";
  const rightBottom =
    slice?.primary?.trusted_by_logo_alignment === "right bottom";

  const logo_marque_data = {
    items: slice.items.map((x) => ({
      brand_image: { ...x.trusted_by_logo },
    })),
  };
  const logosPresent =
    logo_marque_data?.items?.filter((item) => item?.brand_image?.url).length >
    0;

  const scrollToSection = (url) => {
    if (url && url?.charAt(0) === "#") {
      const sectionId = url.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        const elementPosition = section.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - 90;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
      }
    } else toggleModal();
  };
  const isUrl =
    slice?.primary?.cta_link?.url &&
    slice?.primary?.cta_link?.url?.charAt(0) !== "#";
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <div className={`flex flex-col flex-1 gap-3 py-10 mx-auto lg:mx-11 ${isAlignCenter ? "lg:py-12": "lg:py-20"}`}>
        <p
          className={`text-xs sm:text-base font-medium leading-4 sm:leading-6 uppercase font-inter tracking-widest_1 text-purple-1500 pb-3 ${isAlignCenter && "text-center"}`}
        >
          {slice?.primary?.caption_title?.text}
        </p>
        
        <div
          className={`flex flex-col gap-5  ${isAlignCenter ? "lg:flex-col justify-center items-center" : "lg:flex-row lg:gap-10"}`}
        >
          <div
            className={`max-w-[648px] overflow-hidden flex-1 ${isAlignCenter && "flex flex-col justify-center items-center"}`}
          >
            <div
              className={`text-gray-2700 text-3.25xl sm:text-4.5xl font-manrope leading-[45px] sm:leading-12 font-semibold tracking-[-1.8px] sm:tracking-tightest_4 color_style ${isAlignCenter ? "text-center sm:pb-2" : "lg:pb-5 "}`}
              dangerouslySetInnerHTML={{
                __html: slice?.primary?.title?.html,
              }}
            />
            {leftBottom && !isEmpty && logosPresent && !isAlignCenter && (
              <section className="max-w-[400px] xs:max-w-[648px] marquee_padding_desktop hidden lg:block">
                {slice?.primary?.show_logo_caption_title && (
                  <p className="pt-8 pb-5 text-xs font-semibold text-gray-600 uppercase font-manrope tracking-widest_1">
                    Trusted by pioneering AI Teams
                  </p>
                )}
                <LogoMarquee logoArray={logo_marque_data} logoBackground="" />
              </section>
            )}
            {slice?.primary.show_cta && (
              <>
                {rightBottom && !isEmpty && !isAlignCenter && (
                  <CustomButton
                    link={isUrl && slice?.primary?.cta_link?.url}
                    onClick={() =>
                      scrollToSection(slice?.primary?.cta_link?.url)
                    }
                    text={slice?.primary?.cta_text?.text}
                    buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 hidden lg:block`}
                  />
                )}
              </>
            )}
          </div>
          <div
            className={`flex-[0.5] overflow-hidden ${isAlignCenter && "flex flex-col justify-center items-center"}`}
          >
            <p
              className={`text-lg font-normal leading-7 text-gray-600 font-inter tracking-tight_1 ${isAlignCenter && "text-center"}`}
            >
              {slice?.primary?.description?.text}
            </p>
            {rightBottom && !isEmpty && logosPresent && !isAlignCenter && (
              <section className="max-w-[400px] xs:max-w-[556px] hidden lg:block">
                {slice?.primary?.show_logo_caption_title && (
                  <p className="pt-8 pb-5 text-xs font-semibold text-gray-600 uppercase font-manrope tracking-widest_1">
                    Trusted by pioneering AI Teams
                  </p>
                )}
                <LogoMarquee logoArray={logo_marque_data} logoBackground="" />
              </section>
            )}
            {slice?.primary.show_cta && (
              <>
                {leftBottom && !isEmpty && (
                  <CustomButton
                    link={isUrl && slice?.primary?.cta_link?.url}
                    onClick={() =>
                      scrollToSection(slice?.primary?.cta_link?.url)
                    }
                    text={slice?.primary?.cta_text?.text}
                    buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 ${isAlignCenter && "mb-8"}`}
                  />
                )}
                {rightBottom && !isEmpty && (
                  <CustomButton
                    link={isUrl && slice?.primary?.cta_link?.url}
                    onClick={() =>
                      scrollToSection(slice?.primary?.cta_link?.url)
                    }
                    text={slice?.primary?.cta_text?.text}
                    buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 mb-8 block  ${!isAlignCenter && "lg:hidden"}`}
                  />
                )}
              </>
            )}
            {!isEmpty && logosPresent && (
              <section
                className={`max-w-[400px] xs:max-w-[648px]  ${isAlignCenter ? " lg:max-w-4xl marquee_padding_desktop" : "lg:hidden marquee_padding"}`}
              >
                {slice?.primary?.show_logo_caption_title && (
                  <p className="pt-8 text-xs font-semibold text-gray-600 uppercase font-manrope tracking-widest_1">
                    Trusted by pioneering AI Teams
                  </p>
                )}
                <LogoMarquee logoArray={logo_marque_data} />
              </section>
            )}
            {isAlignCenter && isEmpty && (
              <CustomButton
                link={isUrl && slice?.primary?.cta_link?.url}
                onClick={() => scrollToSection(slice?.primary?.cta_link?.url)}
                text={slice?.primary?.cta_text?.text}
                buttonClass={`bg-purple-1500 text-white hover:bg-[#5E24FF] mt-5 `}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroMonet;
