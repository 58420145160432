import React from "react";

const RightToLeftGradiantArrow = () => {
  return (
    <svg
      width="97"
      height="40"
      viewBox="0 0 97 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden lg:block xl:-mr-1"
    >
      <path
        d="M96.4375 20L91.4375 17.1132V22.8868L96.4375 20ZM0.9375 20.5H91.9375V19.5H0.9375V20.5Z"
        fill="url(#paint0_linear_211_12133)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12133"
          x1="0.9375"
          y1="20.5"
          x2="96.4375"
          y2="20.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RightToLeftGradiantArrow;
