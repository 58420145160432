import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ArrowDown } from "../assets/icons/arrowDownIcon";
import { AIAssisted, SAM, SDKIcon } from "../assets/icons/headerIcons";
import {
  AnnotatorTrainingIcon,
  DicomIcon,
  ECGIcon,
  ImageIcon,
  SynthaticIcon,
  VideoIcon,
  WorkFlowsIcon,
} from "../assets/product-header-button";
import CustomLink from "../components/CustomLink";
import { headerTheme } from "./Header";
import { HeaderSubmenuIcon, NextIcon } from "../assets/icons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exploreEngine = [
  {
    heading: "Index",
    description: "Data curation & management",
    imgSrc:
      "https://encord.cdn.prismic.io/encord/cae9ab10-1ee2-4744-9ba1-8e8b1219eabd_index-icon.svg",
    href: "/encord-index/",
  },
  {
    heading: "Annotate",
    description: "Annotation & workflow tools",
    imgSrc:
      "https://encord.cdn.prismic.io/encord/5c6ece91-090b-407e-8b86-bd4e289e8c2b_nav-bar-annotate-icon.svg",
    href: "/annotate/",
    subMenu: [
      {
        heading: "annotation",
        items: [
          {
            text: "Image",
            icon: <ImageIcon />,
            href: "/image/",
          },
          {
            text: "Video",
            icon: <VideoIcon />,
            href: "/video/",
          },
          {
            text: "DICOM & NIfTI",
            icon: <DicomIcon />,
            href: "/dicom/",
          },
          {
            text: "Synthetic-aperture radar",
            icon: <SynthaticIcon />,
            href: "/sar/",
          },
          {
            text: "ECG annotation",
            icon: <ECGIcon />,
            href: "/ecg-annotation-tool/",
          },
        ],
      },
      {
        heading: "QUALITY CONTROL & ASSURANCE",
        items: [
          {
            text: "Workflows",
            icon: <WorkFlowsIcon />,
            href: "/workflows/",
          },
          {
            text: "Annotator training module",
            icon: <AnnotatorTrainingIcon />,
            href: "/annotator-training/",
          },
        ],
      },
      {
        heading: "automation",
        items: [
          {
            text: "Segment Anything Model (SAM)",
            icon: <SAM />,
            href: "/auto-segment/",
          },
          {
            text: "AI-assisted labelling",
            icon: <AIAssisted />,
            href: "/automate/",
          },
          {
            text: "API & Python SDK",
            icon: <SDKIcon />,
            href: "/api/",
          },
        ],
      },
    ],
  },
  {
    heading: "Active",
    description: "Model testing & evaluation",
    imgSrc:
      "https://encord.cdn.prismic.io/encord/e1c65c1a-f67f-42de-b0da-6f86c0927d57_nav-bar-active-icon.svg",
    href: "/active/",
  },
  // {
  //   heading: "Apollo",
  //   description: "Fine-tune visual foundation models",
  //   comingSoon: "true",

  //   imgSrc:
  //     "https://encord.cdn.prismic.io/encord/4a29c582-567f-414c-889d-1cce958345e4_nav-bar-apollo-icon.svg",
  //   href: "/",
  // },
];

export default function ProductHeaderButton({
  theme,
  handleEnter,
  handleLeave,
  ref,
  isHover,
}) {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  useEffect(() => {
    setHoveredIndex(0);
  }, [isHover]);
  return (
    <Popover
      className="relative"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      ref={ref}
    >
      {({ open }) => (
        <>
          <div>
            <Popover.Button
              className={classNames(
                isHover
                  ? headerTheme[theme].hoverTextColor
                  : headerTheme[theme].textColor,
                "group rounded-md inline-flex gap-2 items-center text-sm lgxl_mid:text-base font-medium focus:outline-none"
              )}
            >
              <span>Platform</span>
              <ArrowDown
                fill={
                  isHover
                    ? headerTheme[theme].hoverIcon
                    : headerTheme[theme].icon
                }
              />
            </Popover.Button>

            <Transition
              show={isHover}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              null
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-[18%] xl:-translate-x-[20%] mt-6 px-2 w-screen sm:px-0 max-w-5xl xl:max-w-[1110px]">
                <div
                  className={`${
                    hoveredIndex === 1
                      ? "overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5 shadow-xl"
                      : ""
                  }`}
                >
                  <nav
                    className={`grid ${
                      hoveredIndex === 1
                        ? "grid-cols-3 bg-white"
                        : "grid-cols-1"
                    } xl:flex  `}
                    aria-labelledby="solutions-heading"
                  >
                    <section
                      className={`flex-[0.33] bg-[#F5F5F5] py-[30px] px-3 2xl:p-5 ${
                        hoveredIndex == 1
                          ? "rounded-s-lg"
                          : "rounded-lg shadow-xl"
                      } h-[402px] w-[345px]`}
                    >
                      <h4 className="text-sm font-medium uppercase text-gray-1200">
                        explore our ai data engine{" "}
                      </h4>
                      <ul className="flex flex-col self-center mt-6 gap-y-3">
                        {exploreEngine.map((item, index) => (
                          <li onMouseEnter={() => setHoveredIndex(index)}>
                            <CustomLink
                              to={item?.href}
                              className={`relative flex items-center p-5 transition duration-150 ease-in-out rounded-lg border outline-none ${
                                index === hoveredIndex
                                  ? "border-gray-1400 bg-gray-1600"
                                  : "border-transparent"
                              } gap-x-3 ${
                                item?.comingSoon && "cursor-not-allowed"
                              }`}
                            >
                              <img
                                src={item?.imgSrc}
                                loading="lazy"
                                className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-white"
                                alt={item?.heading || "icon"}
                              />
                              <div className="flex flex-col">
                                <div className="flex gap-2.5 items-center">
                                  <h3 className="text-[#262626] text-xl font-semibold">
                                    {item?.heading}
                                  </h3>
                                  {item?.comingSoon && (
                                    <span className="text-pink-100 font-medium tracking-widest_1 text-xs px-2.5 py-1 bg-[#FFEFF4] rounded-4xl self-center ml-2.5">
                                      COMING SOON
                                    </span>
                                  )}
                                  {index === 0 && (
                                    <span className="text-white text-[10px] h-[19px] font-manrope font-medium px-2 py-[2px] flex justify-center items-center bg-purple-1500 uppercase rounded-[30px]">
                                      New
                                    </span>
                                  )}
                                </div>
                                <p className="text-sm font-medium text-gray-1200 max-w-[256px]">
                                  {item?.description}
                                </p>
                              </div>
                              {item?.subMenu && (
                                <div className="my-auto absolute right-3.5">
                                  <HeaderSubmenuIcon />
                                </div>
                              )}
                            </CustomLink>
                          </li>
                        ))}
                      </ul>
                    </section>

                    <>
                      <section className="flex-[0.3] py-[30px] px-3 2xl:p-5">
                        <h4 className="text-sm font-medium uppercase text-gray-1200">
                          {exploreEngine[hoveredIndex]?.subMenu?.[0]?.heading}
                        </h4>
                        <ul className="flex flex-col mt-6 border-gray-1600 gap-y-3">
                          {exploreEngine[hoveredIndex]?.subMenu?.[0]?.items.map(
                            ({ text, href, icon }, index) => (
                              <li className="group">
                                <CustomLink
                                  to={href}
                                  key={index}
                                  className="flex p-2.5 gap-x-4 items-start rounded-lg  group-hover:bg-[#F5F5F5] outline-none transition ease-in-out duration-150"
                                >
                                  <div className="flex items-center justify-center flex-shrink-0 w-5 h-6 text-white hover:fill-pruple-500">
                                    {icon}
                                  </div>
                                  <p className="text-base font-medium text-gray-600">
                                    {text}
                                  </p>
                                </CustomLink>
                              </li>
                            )
                          )}
                        </ul>
                      </section>

                      <section className="flex-[0.3] py-[30px] px-3 2xl:p-5 flex flex-col gap-6">
                        {exploreEngine[hoveredIndex]?.subMenu
                          ?.slice(1, 3)
                          ?.map((subMenu, index) => (
                            <section>
                              <h4
                                className="text-sm font-medium uppercase text-gray-1200"
                                key={index}
                              >
                                {subMenu?.heading}
                              </h4>
                              <ul className="flex-col mt-6 border-gray-1600flex gap-y-3">
                                {subMenu?.items.map(
                                  ({ text, href, icon }, index) => (
                                    <li className="group">
                                      <CustomLink
                                        to={href}
                                        key={index}
                                        className="flex p-2.5 gap-x-4 items-start rounded-lg  group-hover:bg-[#F5F5F5] outline-none transition ease-in-out duration-150"
                                      >
                                        {icon}
                                        <p className="text-base font-medium text-gray-600">
                                          {text}
                                        </p>
                                      </CustomLink>
                                    </li>
                                  )
                                )}
                              </ul>
                            </section>
                          ))}
                      </section>
                    </>
                  </nav>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
}
