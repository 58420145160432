import React from "react";

const RightHeaderArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178"
      height="21"
      viewBox="0 0 178 21"
      fill="none"
    >
      <path
        d="M176.565 21.0002V1.2002H0.165433"
        stroke="#595959"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />
    </svg>
  );
};

export default RightHeaderArrow;
