import React, { useContext } from "react";
import ModalContext from "../../../Contexts/openModal";
import FeatureRaphaelCard from "../../Pieces/FeatureRaphaelCard";
import HeadingSection from "../../Pieces/HeadingSection";

const FeatureRaphael = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="relative py-10 lg:py-20 lg:mx-11 ">
        <div
          className={`flex flex-col gap-8 lg:gap-10  ${slice?.primary?.layout_style == "left" ? "lg:flex-row" : "lg:flex-row-reverse"} `}
        >
          <div className="lg:max-w-[450px] xl:max-w-[556px]">
            <HeadingSection
              data={slice?.primary}
              toggleModal={toggleModal}
              headingStyle="text-purple-1100 max-w-[495px]"
              sectionStyle="headingSticky"
            />
          </div>
          <div className="relative flex flex-col flex-1 gap-5 scroll-smooth">
            {slice?.items?.map((data, index) => (
              <FeatureRaphaelCard key={index} cardData={data} index={index} />
            ))}
          </div>
        </div>
      </article>
    </section>
  );
};

export default FeatureRaphael;
