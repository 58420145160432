import React from "react";
import "../../../styles/components/Slices/FeatureKlimt.scss";
import FeatureKlimtCards from "../../Pieces/FeatureKlimtCards";
import desktopBG from "../../../assets/backgrounds/FeatureKlimtDesktop.png"
import tabletBG from "../../../assets/backgrounds/FeatureKlimtTablet.png"
import mobileBG from "../../../assets/backgrounds/FeatureKlimtMobile.png"

const FeatureKlimt = ({ slice }) => {
  const firstThreeCards = slice?.items.slice(0, 3);
  const lastThreeCards = slice?.items.slice(-3);
  return (
    <article className="relative">
       <>
          <img
            src={desktopBG}
            className="absolute inset-0 hidden w-full h-full -z-10 md:block"
            alt="desktopBG"
          />
          <img
            src={tabletBG}
            className="absolute inset-0 hidden w-full h-full -z-10 sm:block md:hidden"
            alt="tabletBG"
          />
          <img
            src={mobileBG}
            className="absolute inset-0 block w-full h-full -z-10 sm:hidden"
            alt="mobileBG"
          />
        </>
      <section className="px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <div className="flex flex-col flex-1 gap-10 py-10 mx-auto sm:gap-6 lg:gap-9 lg:py-20 lg:mx-11">
          <div className="justify-center hidden gap-6 sm:flex">
            {firstThreeCards.map((card, index) => (
              <FeatureKlimtCards key={index} data={card} />
            ))}
          </div>
          <div
            className="text-center font-manrope text-2xl text-white sm:py-8 lg:py-7.5 leading-8 sm:text-4.5xl sm:leading-12 font-bold tracking-tightest_5 sm:tracking-tightest_4 heading_strong_gradient"
            dangerouslySetInnerHTML={{
              __html: slice?.primary?.heading?.html,
            }}
          />
          <div className="justify-center hidden gap-6 sm:flex">
            {lastThreeCards.map((card, index) => (
              <FeatureKlimtCards key={index} data={card} />
            ))}
          </div>
          <div className="flex gap-3 overflow-x-auto sm:hidden scroll-smooth no_scrollbar">
            {slice?.items.map((card, index) => (
              <FeatureKlimtCards key={index} data={card} />
            ))}
          </div>
        </div>
      </section>
    </article>
  );
};

export default FeatureKlimt;
