import React from "react";

const LeftToRightTabletArrow = ({styleClass}) => {
  return (
    <svg
      width="73"
      height="6"
      viewBox="0 0 73 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${styleClass}`}
    >
      <path
        d="M73 3L68 0.113249V5.88675L73 3ZM0 3.5H1.01389V2.5H0V3.5ZM3.04167 3.5H5.06944V2.5H3.04167V3.5ZM7.09722 3.5H9.125V2.5H7.09722V3.5ZM11.1528 3.5H13.1806V2.5H11.1528V3.5ZM15.2083 3.5H17.2361V2.5H15.2083V3.5ZM19.2639 3.5H21.2917V2.5H19.2639V3.5ZM23.3194 3.5H25.3472V2.5H23.3194V3.5ZM27.375 3.5H29.4028V2.5H27.375V3.5ZM31.4306 3.5H33.4583V2.5H31.4306V3.5ZM35.4861 3.5H37.5139V2.5H35.4861V3.5ZM39.5417 3.5H41.5695V2.5H39.5417V3.5ZM43.5972 3.5H45.625V2.5H43.5972V3.5ZM47.6528 3.5H49.6806V2.5H47.6528V3.5ZM51.7083 3.5H53.7361V2.5H51.7083V3.5ZM55.7639 3.5H57.7917V2.5H55.7639V3.5ZM59.8195 3.5H61.8472V2.5H59.8195V3.5ZM63.875 3.5H65.9028V2.5H63.875V3.5ZM67.9306 3.5H69.9584V2.5H67.9306V3.5Z"
        fill="#595959"
      />
    </svg>
  );
};

export default LeftToRightTabletArrow;
