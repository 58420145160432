import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

/**
 * CustomLink component is a wrapper around Gatsby's Link component that
 * adds the domain name as a prefix to the provided URL. This is useful
 * when you want to specify an absolute URL that includes the domain name.
 *
 * @param {Object} props - The component props.
 * @param {string} props.to - The destination URL.
 * @param {Object} [rest] - Any additional props to pass through to the Link component.
 *
 * @returns {JSX.Element} A custom Link component.
 */

const CustomLink = ({ to, ...rest }) => {
  const location = useLocation();
  const domain = location.origin || "https://encord.com";
  const prefixedTo = `${domain}${to}`;
  return <Link to={prefixedTo} {...rest} />;
};

export default CustomLink;
