export const customFaqData = {
  "id": "table$64712eb4-5bf4-4fc3-8aac-017562585e70",
  "items": [
      {
          "answer": {
              "text": "Yes. In addition to being able to train models & run inference using our platform, you can either import model predictions via our APIs & Python SDK, integrate your model in the Encord annotation interface if it is deployed via API, or upload your own model weights. ",
              "html": "<p></p><p>Yes. In addition to being able to <strong><a href=\"https://docs.encord.com/docs/apollo-models#training-models\" rel=\"noopener noreferrer\">train models &amp; run inference</a></strong> using our platform, you can either import model predictions via our <strong><a href=\"https://docs.encord.com/docs/annotate-api-overview\" rel=\"noopener noreferrer\">APIs &amp; Python SDK</a></strong>, integrate your model in the Encord annotation interface if it is deployed via API, or upload your own model weights.</p><p></p>"
          },
          "question": {
              "html": "<p>Can I use my own model in Encord?</p>",
              "text": "Can I use my own model in Encord?"
          }
      },
      {
          "answer": {
              "text": "At Encord, we take our security commitments very seriously. When working with us and using our services, you can ensure your and your customer's data is safe and secure. You always own labels, data & models, and Encord never shares any of your data with any third party. Encord is hosted securely on the Google Cloud Platform (GCP). Encord native integrations with private cloud buckets, ensuring that data never has to leave your own storage facility.\n\nAny data passing through the Encord platform is encrypted both in-transit using TLS and at rest.\n\nEncord is HIPAA&GDPR compliant, and maintains SOC2 Type II certification. Learn more about data security at Encord here.\n",
              "html": "<p>At Encord, we take our security commitments very seriously. When working with us and using our services, you can ensure your and your customer&#39;s data is safe and secure. You always own labels, data &amp; models, and Encord never shares any of your data with any third party. Encord is hosted <strong><a href=\"https://cloud.google.com/security\" rel=\"noopener noreferrer\">securely on the Google Cloud Platform (GCP)</a></strong>. Encord native <strong><a href=\"https://docs.encord.com/docs/annotate-private-cloud-integration\" rel=\"noopener noreferrer\">integrations with private cloud buckets</a></strong>, ensuring that data never has to leave your own storage facility.<br /><br />Any data passing through the Encord platform is encrypted both in-transit using TLS and at rest.<br /><br />Encord is <strong><a href=\"https://www.hhs.gov/hipaa/index.html\" rel=\"noopener noreferrer\">HIPAA</a></strong>&amp;<strong><a href=\"https://gdpr-info.eu/\" rel=\"noopener noreferrer\">GDPR</a> </strong>compliant, and maintains <strong><a href=\"https://www.aicpa-cima.com/topic/audit-assurance/audit-and-assurance-greater-than-soc-2\" rel=\"noopener noreferrer\">SOC2 Type II</a> </strong>certification. Learn more about data security at Encord <strong><a href=\"https://encord.com/security-at-encord/\" rel=\"noopener noreferrer\">here</a></strong>.<br /></p>"
          },
          "question": {
              "html": "<p>How is data handled by Encord?</p>",
              "text": "How is data handled by Encord?"
          }
      },
      {
          "answer": {
              "text": "Yes. If you believe you’ve discovered a bug in Encord’s security, please get in touch at security@encord.com. Our security team promptly investigates all reported issues. Learn more about data security at Encord here.",
              "html": "<p>Yes. If you believe you’ve discovered a bug in Encord’s security, please get in touch at <strong>security@encord.com</strong>. Our security team promptly investigates all reported issues. Learn more about data security at Encord <strong><a href=\"https://encord.com/security-at-encord/\" rel=\"noopener noreferrer\">here</a></strong>.</p>"
          },
          "question": {
              "html": "<p>Does Encord have a vulnerability disclosure program in place?</p>",
              "text": "Does Encord have a vulnerability disclosure program in place?"
          }
      },
      {
          "answer": {
              "text": "Yes - we offer managed on-demand premium labeling-as-a-service designed to meet your specific business objectives and offer our expert support to help you meet your goals. Our active learning platform and suite of tools are designed to automate the annotation process and maximise the ROI of each human input. The purpose of our software is to help you label less data.",
              "html": "<p>Yes - we offer managed on-demand premium <strong><a href=\"https://encord.com/labeling-service/\" rel=\"noopener noreferrer\">labeling-as-a-service</a> </strong>designed to meet your specific business objectives and offer our expert support to help you meet your goals. Our active learning platform and suite of tools are designed to automate the annotation process and maximise the ROI of each human input. The purpose of our software is to help you label less data.</p>"
          },
          "question": {
              "html": "<p>Does Encord provide data labeling services?</p>",
              "text": "Does Encord provide data labeling services?"
          }
      },
      {
          "answer": {
              "text": "The best way to spend less on labeling is using purpose-built annotation software, automation features, and active learning techniques. Encord's platform provides several automation techniques, including model-assisted labeling & auto-segmentation. High-complexity use cases have seen 60-80% reduction in labeling costs. ",
              "html": "<p></p><p>The best way to spend less on labeling is using purpose-built annotation software, automation features, and active learning techniques. Encord&#39;s platform provides several automation techniques, including <strong><a href=\"https://encord.com/automate/\" rel=\"noopener noreferrer\">model-assisted labeling</a> </strong>&amp; auto-segmentation. High-complexity use cases have seen 60-80% reduction in labeling costs.</p><p></p>"
          },
          "question": {
              "html": "<p>How does Encord help me spend less time and cost labeling?</p>",
              "text": "How does Encord help me spend less time and cost labeling?"
          }
      },
      {
          "answer": {
              "text": "Encord offers three different support plans: standard, premium, and enterprise support. Note that custom service agreements and uptime SLAs require an enterprise support plan. Learn more about our support plans here. ",
              "html": "<p></p><p>Encord offers three different support plans: standard, premium, and enterprise support. Note that custom service agreements and uptime SLAs require an enterprise support plan. Learn more about our support plans <strong><a href=\"https://encord.com/support-plans/\" rel=\"noopener noreferrer\">here</a></strong>.</p><p></p>"
          },
          "question": {
              "html": "<p>What support plans does Encord offer?</p>",
              "text": "What support plans does Encord offer?"
          }
      }
  ],
  "primary": {
      "section_heading": {
          "text": "Frequently asked questions"
      }
  },
  "slice_type": "table"
}