import React, { useRef } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ArrowDown } from "../assets/icons/arrowDownIcon";
import {
  InformationCircleIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  RssIcon,
  BookOpenIcon,
  GlobeAltIcon,
  VideoCameraIcon,
  DatabaseIcon,
} from "@heroicons/react/outline";

import CustomLink from "../components/CustomLink";
import { headerTheme } from "./Header";

export const resources = [
  // {
  //   name: "Community",
  //   href: "https://join.slack.com/t/encord-devs/shared_invite/zt-24z7s3k0k-k8IcEU4iMz93erouJtVCPA?",
  //   icon: GlobeAltIcon,
  //   external: true,
  // },
  {
    name: "Blog",
    href: "/blog/",
    icon: RssIcon,
    external: true,
  },
  {
    name: "Webinars",
    href: "/webinars/",
    icon: VideoCameraIcon,
    external: true,
  },
  {
    name: "Security",
    href: "/security-at-encord/",
    icon: ShieldCheckIcon,
    external: true,
  },
  // {
  //   name: "Datasets",
  //   href: "/lp/youtube-vos-dataset/",
  //   icon: DatabaseIcon,
  //   external: true,
  // },
  {
    name: "Documentation",
    href: "https://docs.encord.com/",
    icon: BookOpenIcon,
    external: true,
  },
  {
    name: "Learn",
    href: "/learning-hub/",
    icon: LightBulbIcon,
    external: true,
  },
  {
    name: "Glossary",
    href: "/glossary/",
    icon: InformationCircleIcon,
    external: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ResourcesHeaderButton({
  theme,
  handleEnter,
  handleLeave,
  ref,
  isHover,
}) {
  return (
    <Popover
      className="relative"
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      ref={ref}
    >
      {({ open }) => (
        <>
          <div>
            <Popover.Button
              className={classNames(
                isHover
                  ? headerTheme[theme].hoverTextColor
                  : headerTheme[theme].textColor,
                "group rounded-md inline-flex gap-2 items-center text-sm lgxl_mid:text-base font-medium focus:outline-none"
              )}
            >
              <span>Resources</span>

              <ArrowDown
                fill={
                  isHover
                    ? headerTheme[theme].hoverIcon
                    : headerTheme[theme].icon
                }
              />
            </Popover.Button>

            <Transition
              show={isHover}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen px-2 mt-3 transform -translate-x-1/2 max-w-fit left-1/2 sm:px-0">
                <div
                  className="overflow-hidden border border-gray-200 rounded-lg ring-1 ring-black ring-opacity-5"
                  style={{
                    boxShadow:
                      "0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div className="relative grid gap-6 p-4 bg-white sm:gap-6 sm:p-6">
                    <ul className="space-y-6">
                      {resources.map((item) => (
                        <li key={item.name} className="flow-root group">
                          {item.external ? (
                            <a
                              href={item.href}
                              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 group-hover:bg-[#F5F5F5] outline-none transition ease-in-out duration-150"
                            >
                              <item.icon
                                className="flex-shrink-0 w-6 h-6 text-gray-400 group-hover:stroke-purple-1500"
                                aria-hidden="true"
                              />
                              <span className="ml-4 text-base font-medium text-gray-600">
                                {item.name}
                              </span>
                            </a>
                          ) : (
                            <CustomLink
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-[#F5F5F5] outline-none transition ease-in-out duration-150"
                            >
                              <item.icon
                                className="flex-shrink-0 w-6 h-6 text-gray-400 group-hover:stroke-purple-1500"
                                aria-hidden="true"
                              />
                              <span className="ml-4 text-base font-medium text-gray-600">
                                {item.name}
                              </span>
                            </CustomLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
}
