import React, { useContext } from "react";
import TestimonialCardsMarquee from "../../Pieces/TestimonialCardsMarquee";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";

const TestimonialRaphael = ({slice }) => {
  const {toggleModal } = useContext(ModalContext);
  return (
    <div className="py-10 lg:py-20">
      <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <article className="lg:mx-11">
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
          />
        </article>
      </section>
      <div className="flex flex-col gap-[18px]">
        <TestimonialCardsMarquee
          data={slice?.items}
          cardColor={slice?.primary?.card_color}
        />
        {slice?.primary?.card_layout === "2 rows" && (
          <TestimonialCardsMarquee
            data={slice?.items}
            cardColor={slice?.primary?.card_color}
            leftToRight={true}
          />
        )}
      </div>
    </div>
  );
};

export default TestimonialRaphael;
