import React, { useContext } from "react";
import ModalContext from "../../Contexts/openModal";
import DemoModal from "../DemoModal";
import CTAPicasso from "./CTA Picasso";
import CareerBenefits from "./CareerBenefits";
import FeatureDali from "./FeatureDali";
import FeatureDegard from "./FeatureDegard";
import FeatureKlimt from "./FeatureKlimt";
import FeatureMatisse from "./FeatureMatisse";
import FeatureMonet from "./FeatureMonet";
import FeatureRaphael from "./FeatureRaphael";
import FeatureTurner from "./FeatureTurner";
import HeroMonet from "./HeroMonet";
import HeroRaphael from "./HeroRaphael";
import HeroSupportingAssetMonet from "./HeroSupportingAssetMonet";
import JobListingMonet from "./JobListingMonet";
import TestimonialMonet from "./TestimonialMonet";
import TestimonialRaphael from "./TestimonialRaphael";
import TestimonialTurner from "./TestimonialTurner";
import Company from "./Company";
import FeaturePicasso from "./FeaturePicasso";
import { LogosMonet } from "./LogosMonet";
import { SectionDali } from "./SectionDali";
import { SectionMonet } from "./SectionMonet";
import { HeroDali } from "./HeroDali";
import { FeatureMunch } from "./FeatureMunch";
import SectionKusama from "./SectionKusama";
import StatsMonet from "./StatsMonet";
import SectionPollock from "./SectionPollock";
import HeroKusama from "./HeroKusama";
import SectionKahlo from "./SectionKahlo";
import DiagramMonet from "./DiagramMonet";
import FooterBanner from "./FooterBanner";
import LogosDali from "./LogosDali";

const allSlices = {
  feature_monet: (slice) => <FeatureMonet {...{ slice }} />,
  hero_monet: (slice, location) => <HeroMonet {...{ slice, location }} />,
  testimonial_turner: (slice) => <TestimonialTurner {...{ slice }} />,
  testimonial_raphael: (slice) => <TestimonialRaphael {...{ slice }} />,
  testimonial_monet: (slice) => <TestimonialMonet {...{ slice }} />,
  feature_turner: (slice) => <FeatureTurner {...{ slice }} />,
  feature_raphael: (slice) => <FeatureRaphael {...{ slice }} />,
  feature_dali: (slice) => <FeatureDali {...{ slice }} />,
  cta_picasso: (slice) => <CTAPicasso {...{ slice }} />,
  hero_supporting_asset_monet: (slice) => (
    <HeroSupportingAssetMonet {...{ slice }} />
  ),
  hero_raphael: (slice) => <HeroRaphael {...{ slice }} />,
  feature_klimt: (slice) => <FeatureKlimt {...{ slice }} />,
  feature_matisse: (slice) => <FeatureMatisse {...{ slice }} />,
  feature_degard: (slice) => <FeatureDegard {...{ slice }} />,
  career_jobs: (slice) => <JobListingMonet {...{ slice }} />,
  career_benifets: (slice) => <CareerBenefits {...{ slice }} />,
  company: (slice, location) => <Company {...{ slice, location }} />,
  feature_picasso: (slice, location) => (
    <FeaturePicasso {...{ slice, location }} />
  ),
  logos_monet: (slice) => <LogosMonet {...{ slice }} />,
  section_dali: (slice) => <SectionDali {...{ slice }} />,
  section_monet: (slice) => <SectionMonet {...{ slice }} />,
  hero_dali: (slice, location) => <HeroDali {...{ slice, location }} />,
  feature_munch: (slice, location) => <FeatureMunch {...{ slice, location }} />,
  section_kusama: (slice) => <SectionKusama {...{ slice }} />,
  stats_monet: (slice, location) => <StatsMonet {...{ slice, location }} />,
  section_pollock: (slice, location) => (
    <SectionPollock {...{ slice, location }} />
  ),
  hero_kusama: (slice, location) => <HeroKusama {...{ slice, location }} />,
  section_kahlo: (slice, location) => <SectionKahlo {...{ slice, location }} />,
  diagram_monet: (slice, location) => <DiagramMonet {...{ slice, location }} />,
  footer_banner: (slice, location) => <FooterBanner {...{ slice, location }} />,
  logos_dali: (slice, location) => <LogosDali {...{ slice, location }} />,
};

const Slices = ({ slices, location }) => {
  const { isOpen, toggleModal, email } = useContext(ModalContext);
  return (
    <>
      <div>
        {slices?.prismicPageV3?.data?.body?.map((slice, index) => {
          const renderer = allSlices[slice?.slice_type];
          return renderer ? (
            <div
              key={`{slice_${slice?.id}}`}
              className={`${index === 0 && slice.slice_type !== "hero_raphael" ? "pt-32" : ""} `}
            >
              {renderer(slice, location, toggleModal)}
            </div>
          ) : null;
        })}
      </div>
      <DemoModal
        title={`Get started`}
        isOpen={isOpen}
        email={email}
        closeModal={toggleModal}
        location={location}
      />
    </>
  );
};

export default Slices;
