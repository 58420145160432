import React from "react";

const UptoDownGradientArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="20"
      viewBox="0 0 6 20"
      fill="none"
      className="self-center lg:hidden"
    >
      <path
        d="M3 20L5.88675 15H0.113249L3 20ZM2.5 0L2.5 15.5H3.5L3.5 0L2.5 0Z"
        fill="url(#paint0_linear_211_12793)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_211_12793"
          x1="2.5"
          y1="0"
          x2="2.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BFBFBF" />
          <stop offset="1" stop-color="#5E44FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UptoDownGradientArrow;
