import React, { useState } from "react";
import "../../../styles/components/Slices/CareerBenefits.scss";
import { ForwardArrow } from "../../../assets/icons/CareerPage/ForwardArrow";
import CustomButton from "../../CustomButton";
import { ShowFeature } from "../../../assets/icons/showFeatures";
import { CloseFeature } from "../../../assets/icons/closeFeatures";
import { useMediaQuery } from "react-responsive";
import { Link } from "gatsby-link";

const CareerBenefits = ({ slice }) => {
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };
  const isMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div
      className={`max-w-6xl gap-5 md:gap-10 pt-10 mb-20 flex flex-col md:flex-row sm:px-12 lg:px-12 xl:px-0 px-4 mx-auto `}
    >
      <div className="primary-section-bg min-h-[138px] md:min-h-[228px] flex flex-col justify-between p-5">
        <div className="flex flex-col gap-2.5 ">
          <span
            className={`text-purple-1100 px-3.5 font-semibold py-1.5 max-w-fit ${slice?.primary?.show_caption ? "block" : "hidden"}  rounded-md text-xs font-manrope uppercase -tracking-tightest_5 bg-[#F2F0FF]`}
          >
            {slice?.primary?.caption_title?.text}
          </span>
          <p className="text-white max-w-[168px] xs:max-w-full lg:max-w-[212px] text-2xl xs:text-2.5xl font-semibold font-manrope tracking-[-1.2px] sm:tracking-[-1.5px]">
            {slice?.primary?.caption_text?.text}
          </p>
          <p className="text-white opacity-80 font-normal text-base -tracking-[0.4px]">
            {slice?.primary?.descriptor_text?.text}
          </p>
        </div>

        <Link className="justify-end cursor-pointer hidden md:flex mt-auto">
          <ForwardArrow />
        </Link>
      </div>

      <div
        className={`mobile-Screen flex transition-all ease-in-out duration-1000 flex-row flex-wrap md:grid ${show && isMobile ? "Mobile" : "h-52 sm:h-full benefits-blur-effect"} md:grid-cols-2 overflow-hidden gap-3`}
      >
        {slice?.items.map((benefit, index) => {
          return (
            <div
              key={index}
              className="relative flex gap-1.5 max-w-fit flex-row items-center  max-h-[191px] px-3 py-1.5 h-full bg-gray-1600 rounded-md border border-gray-1600"
            >
              <img
                src={benefit?.benefit_icon?.url}
                alt={benefit.alt || "img"}
              />
              <p className="font-medium text-sm md:text-base -tracking-[0.56px] text-gray-2500">
                {benefit.benefit_text?.text}
              </p>
            </div>
          );
        })}
      </div>
      <div className="sm:hidden flex mx-auto">
        {show ? (
          <CustomButton
            onClick={handleToggle}
            text={"Less"}
            icon={<CloseFeature />}
            buttonClass="text-gray-2500 sm:text-lg mx-auto border border-gray-2200"
          />
        ) : (
          <CustomButton
            onClick={handleToggle}
            text={"More"}
            icon={<ShowFeature />}
            buttonClass="text-gray-2500 sm:text-lg mx-auto border border-gray-2200"
          />
        )}
      </div>
    </div>
  );
};
export default CareerBenefits;
