import React, { useEffect } from "react";
import { BlogBackIcon } from "../../../assets/icons";
import Catalog from "../../../components/Catalog";
import SideBlogCtaBanner from "./SideBlogCtaBanner";
import AuthorCard from "./AuthorCard";
import mediumZoom from "medium-zoom";
import CtaSide from "../../../partials/CtaSide";
import NewLeadForm from "../../../components/NewLeadForm";
import { navigate } from "@reach/router";
import { pickSlice } from "../../../utilities/helpers";

/**
 * @summary This method appends `TryEncord` and `Table` component in the
 * place of {{try_encord}} and {{table}} respectively and adds
 * the catalogue from h2, h3, and h4..
 * @param {Object} blog blog object from api
 * @author Wajeeh Aslam
 * @returns Object {cotalog, __html}
 */
const BlogContent = ({ blog, location, html, catalogContent, handleLink }) => {
  const showBlogForm = blog.uid === "european-ai-act-learning-pack";
  const pathname = location?.pathname;
  useEffect(() => {
    mediumZoom(
      document.querySelectorAll("p.block-img > img", {
        container: "#zoom-container",
      })
    );
  }, []);
  const [other_authors] = pickSlice(blog?.data, "other_authors");

  return (
    <>
      <div id="zoom-container" />
      <div className="grid grid-cols-1 md:grid-cols-1 lg:md:grid-cols-[290px_1fr]">
        <section className="order-2 md:order-2 max-h-[calc(100vh - 200px)]">
          <div className="sticky top-32">
            <div className="flex-col justify-between hidden lg:flex lg:flex-col sm:flex-row">
              <div
                onClick={() => navigate(handleLink())}
                className={`${
                  !catalogContent.length && "mb-4"
                } text-base font-semibold leading-6 tracking-tight_1 flex items-center gap-1.5 text-gray-600 cursor-pointer font-manrope`}
              >
                <BlogBackIcon />
                Back to{" "}
                {!pathname.startsWith("/blog") ? "Case Studies" : "Blogs"}
              </div>
              {pathname.startsWith("/blog") && (
                <div className="flex-col hidden gap-8 mt-10 lg:flex">
                  <SideBlogCtaBanner />
                </div>
              )}
              {location?.pathname.includes("customers") &&
                blog?.tags?.includes("Customers") && (
                  <div className="flex-col hidden gap-6 mt-10 lg:flex">
                    <CtaSide
                      title="Think Encord could be a good fit for your team as well?"
                      btnText="Book a demo"
                      btnLink="https://encord.com/try-it-free/"
                      containerClasses="!m-0"
                    />
                  </div>
                )}
              <div className="lg:w-auto w-full max-w-[341px] ">
                {!!catalogContent.length && (
                  <Catalog catalogContent={catalogContent} />
                )}
              </div>
            </div>

            {pathname.startsWith("/blog") && (
              <>
                <div className={`flex-col hidden gap-8 lg:flex pt-10`}>
                  <div className="flex items-center gap-1.5">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.6875 2.20117H9.55937C8.88809 2.20117 8.23184 2.39395 7.66719 2.75762L7 3.18555L6.33281 2.75762C5.76873 2.39402 5.11174 2.20081 4.44062 2.20117H1.3125C1.07051 2.20117 0.875 2.39668 0.875 2.63867V10.4043C0.875 10.6463 1.07051 10.8418 1.3125 10.8418H4.44062C5.11191 10.8418 5.76816 11.0346 6.33281 11.3982L6.93984 11.7893C6.95762 11.8002 6.97813 11.807 6.99863 11.807C7.01914 11.807 7.03965 11.8016 7.05742 11.7893L7.66445 11.3982C8.23047 11.0346 8.88809 10.8418 9.55937 10.8418H12.6875C12.9295 10.8418 13.125 10.6463 13.125 10.4043V2.63867C13.125 2.39668 12.9295 2.20117 12.6875 2.20117ZM4.44062 9.85742H1.85938V3.18555H4.44062C4.92461 3.18555 5.39492 3.32363 5.80098 3.58477L6.46816 4.0127L6.5625 4.07422V10.3906C5.91172 10.0406 5.18437 9.85742 4.44062 9.85742ZM12.1406 9.85742H9.55937C8.81563 9.85742 8.08828 10.0406 7.4375 10.3906V4.07422L7.53184 4.0127L8.19902 3.58477C8.60508 3.32363 9.07539 3.18555 9.55937 3.18555H12.1406V9.85742ZM5.42637 4.93555H2.88613C2.83281 4.93555 2.78906 4.98203 2.78906 5.03809V5.65332C2.78906 5.70938 2.83281 5.75586 2.88613 5.75586H5.425C5.47832 5.75586 5.52207 5.70938 5.52207 5.65332V5.03809C5.52344 4.98203 5.47969 4.93555 5.42637 4.93555ZM8.47656 5.03809V5.65332C8.47656 5.70938 8.52031 5.75586 8.57363 5.75586H11.1125C11.1658 5.75586 11.2096 5.70938 11.2096 5.65332V5.03809C11.2096 4.98203 11.1658 4.93555 11.1125 4.93555H8.57363C8.52031 4.93555 8.47656 4.98203 8.47656 5.03809ZM5.42637 6.84961H2.88613C2.83281 6.84961 2.78906 6.89609 2.78906 6.95215V7.56738C2.78906 7.62344 2.83281 7.66992 2.88613 7.66992H5.425C5.47832 7.66992 5.52207 7.62344 5.52207 7.56738V6.95215C5.52344 6.89609 5.47969 6.84961 5.42637 6.84961ZM11.1139 6.84961H8.57363C8.52031 6.84961 8.47656 6.89609 8.47656 6.95215V7.56738C8.47656 7.62344 8.52031 7.66992 8.57363 7.66992H11.1125C11.1658 7.66992 11.2096 7.62344 11.2096 7.56738V6.95215C11.2109 6.89609 11.1672 6.84961 11.1139 6.84961Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                    <h6 className="text-gray-1200 text-[10px] font-semibold tracking-[1.2px] font-manrope uppercase ">
                      Written by
                    </h6>
                  </div>
                  <AuthorCard author={blog?.data?.author?.document} />
                  {other_authors?.items?.map((auth, i) => {
                    return (
                      <AuthorCard
                        key={i}
                        author={auth?.other_authors?.document}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </section>

        <section className={`lg:pt-12 encode-blogs-content order-3 md:order-2`}>
          <div className="mb-4 lg:pl-16">
            <div className="flex flex-col-reverse gap-3 lgxl_mid:flex-row">
              <div
                className="flex-1 flex-shrink-0 max-w-full"
                dangerouslySetInnerHTML={{ __html: html }}
              />

              {showBlogForm && (
                <div className="flex justify-center flex-1 mx-auto">
                  <div className="max-w-[700px] self-center xl:my-0 my-5 px-5 xl:px-0 lg:!mb-auto">
                    <section className="flex flex-col px-5 py-7 lg::py-14 lg:max-w-[34rem] max-w-[32rem] w-full rounded-2xl form_shadow">
                      <h3>EU AI Act Learning Pack</h3>
                      <div className="flex items-center min-h-[343px] relative">
                        <NewLeadForm
                          region="eu1"
                          loader={
                            <div className="absolute left-2/4 -translate-x-2/4">
                              <svg
                                class="h-6 w-6 animate-spin"
                                viewBox="3 3 18 18"
                              >
                                <path
                                  class="fill-gray-200"
                                  d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                ></path>
                                <path
                                  class="fill-gray-800"
                                  d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                ></path>
                              </svg>
                            </div>
                          }
                          portalId="25381551"
                          formId="7001d410-8c01-4300-9d8a-50daba2655df"
                        />
                      </div>
                    </section>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="order-3 ">
          <div className="sticky top-28 pt-[1px]">
            {pathname.startsWith("/blog") && (
              <div className="flex flex-col gap-5 lg:hidden mb-2.5 mt-5">
                <SideBlogCtaBanner />
                <div className="flex items-center gap-1.5">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.6875 2.20117H9.55937C8.88809 2.20117 8.23184 2.39395 7.66719 2.75762L7 3.18555L6.33281 2.75762C5.76873 2.39402 5.11174 2.20081 4.44062 2.20117H1.3125C1.07051 2.20117 0.875 2.39668 0.875 2.63867V10.4043C0.875 10.6463 1.07051 10.8418 1.3125 10.8418H4.44062C5.11191 10.8418 5.76816 11.0346 6.33281 11.3982L6.93984 11.7893C6.95762 11.8002 6.97813 11.807 6.99863 11.807C7.01914 11.807 7.03965 11.8016 7.05742 11.7893L7.66445 11.3982C8.23047 11.0346 8.88809 10.8418 9.55937 10.8418H12.6875C12.9295 10.8418 13.125 10.6463 13.125 10.4043V2.63867C13.125 2.39668 12.9295 2.20117 12.6875 2.20117ZM4.44062 9.85742H1.85938V3.18555H4.44062C4.92461 3.18555 5.39492 3.32363 5.80098 3.58477L6.46816 4.0127L6.5625 4.07422V10.3906C5.91172 10.0406 5.18437 9.85742 4.44062 9.85742ZM12.1406 9.85742H9.55937C8.81563 9.85742 8.08828 10.0406 7.4375 10.3906V4.07422L7.53184 4.0127L8.19902 3.58477C8.60508 3.32363 9.07539 3.18555 9.55937 3.18555H12.1406V9.85742ZM5.42637 4.93555H2.88613C2.83281 4.93555 2.78906 4.98203 2.78906 5.03809V5.65332C2.78906 5.70938 2.83281 5.75586 2.88613 5.75586H5.425C5.47832 5.75586 5.52207 5.70938 5.52207 5.65332V5.03809C5.52344 4.98203 5.47969 4.93555 5.42637 4.93555ZM8.47656 5.03809V5.65332C8.47656 5.70938 8.52031 5.75586 8.57363 5.75586H11.1125C11.1658 5.75586 11.2096 5.70938 11.2096 5.65332V5.03809C11.2096 4.98203 11.1658 4.93555 11.1125 4.93555H8.57363C8.52031 4.93555 8.47656 4.98203 8.47656 5.03809ZM5.42637 6.84961H2.88613C2.83281 6.84961 2.78906 6.89609 2.78906 6.95215V7.56738C2.78906 7.62344 2.83281 7.66992 2.88613 7.66992H5.425C5.47832 7.66992 5.52207 7.62344 5.52207 7.56738V6.95215C5.52344 6.89609 5.47969 6.84961 5.42637 6.84961ZM11.1139 6.84961H8.57363C8.52031 6.84961 8.47656 6.89609 8.47656 6.95215V7.56738C8.47656 7.62344 8.52031 7.66992 8.57363 7.66992H11.1125C11.1658 7.66992 11.2096 7.62344 11.2096 7.56738V6.95215C11.2109 6.89609 11.1672 6.84961 11.1139 6.84961Z"
                      fill="#8C8C8C"
                    />
                  </svg>
                  <h6 className="text-gray-1200 text-[10px] font-semibold tracking-[1.2px] font-manrope uppercase ">
                    Written by
                  </h6>
                </div>
                <AuthorCard author={blog?.data?.author?.document} />
                {other_authors?.items?.map((auth, i) => {
                  return (
                    <AuthorCard
                      key={i}
                      author={auth?.other_authors?.document}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogContent;
