import React, { useState } from "react";
import "../../../styles/components/Slices/SectionKahlo.scss";
import { useMediaQuery } from "react-responsive";

const SectionKahlo = ({ slice }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return (
    <section className="mx-auto max-w-6xl overflow-x-auto border-none xl:px-0 sm:px-12 scroll-smooth no-scrollbar pt-0 pb-10 md:pt-10 md:pb-10 sm px-4 gap-2 xs:gap-2.5 md:gap-6 flex">
      {slice?.items?.map((value, index) => {
        const isHovered = hoveredIndex === index;
        return (
          <div
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            key={index}
            className="min-w-[288px] sm:max-w-[368px] group sm:min-w-[368px] kahlo_card_bg px-3.5 py-5 sm:px-5 sm:py-7 md:px-[22px] md:py-[22px]"
          >
            <div className="flex gap-2.5 items-start min-h-[56px]">
              {isHovered && isDesktop ? (
                <img className="w-6" src={value?.hovered_icon?.url} />
              ) : !isDesktop ? (
                <img className="w-6" src={value?.hovered_icon?.url} />
              ) : (
                <img className="w-6" src={value?.icon?.url} />
              )}

              <p className="text-lg md:text-gray-2700 text-purple-1100 sm:text-[20px] md:group-hover:text-purple-1100 font-manrope font-semibold tracking-tighter_1">
                {value?.header_title?.text}
              </p>
            </div>
            <p className="pt-2.5 text-sm md:text-base text-gray-600">
              {value?.descriptor_text?.text}
            </p>
          </div>
        );
      })}
    </section>
  );
};

export default SectionKahlo;
