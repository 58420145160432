import React from "react";
import CustomButton from "../../CustomButton";
import { Chevron } from "../../../assets/icons";
import SectionPollockCard from "../../Pieces/SectionPollockCard";

const SectionPollock = ({ slice }) => {
  return (
    <section className="relative mx-5 max-w-7xl xl:px-16 xl:mx-auto sm:mx-6">
      <article className="py-10">
        <section className="flex flex-col items-center justify-center space-y-4">
          <div className="flex justify-center gap-2.5 items-center">
            <img className="w-10" src={slice?.primary?.icon?.url} />
            <p className="text-2.5xl sm:text-3.25xl font-manrope text-purple-1100 font-bold tracking-tightest_7">
              {slice?.primary?.section_title?.text}
            </p>
          </div>
          <p className="text-sm font-normal text-gray-600 sm:text-base tracking-tight_1">
            {slice?.primary?.descriptor_text?.text}
          </p>
          <CustomButton
            icon={<Chevron fill={undefined} isDarkMode={undefined} />}
            text={slice?.primary?.cta_title?.text}
            link={undefined}
            buttonClass={`bg-gray-1600`}
            onClick={undefined}
            direction={`right`}
            isFullWidth={undefined}
          />
        </section>
        <section className="grid grid-cols-1 gap-6 mt-12 lg:grid-cols-6 ">
          {slice?.items?.map(
            (
              {
                header_title,
                descriptor_text,
                card_video,
                image,
                cta_title,
                cta_link,
                show_cta,
              },
              index
            ) => (
              <div
                className={`${index == 0 ? "lg:col-span-4" : "lg:col-span-2"}`}
                key={index}
              >
                <SectionPollockCard
                  sectionHeading={header_title?.text}
                  sectionDescription={descriptor_text?.text}
                  sectionVideo={card_video?.url}
                  sectionImage={image}
                  linkText={cta_title?.text}
                  linkUrl={cta_link?.url}
                  assetClass={index == 1 && "h-[322px] justify-center"}
                  showCta={show_cta}
                  cardContainerClass={index == 1 && "!lg:w-[428px]"}
                />
              </div>
            )
          )}
        </section>
      </article>
    </section>
  );
};
export default SectionPollock;
