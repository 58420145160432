import React, { useContext, useState } from "react";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/HeroDali.scss";
import CustomButton from "../../CustomButton";
import { SingleEmailForm } from "../../Pieces/SingleEmailForm";
import SingleEmailForm2 from "../../Pieces/SingleEmailForm2";

export const DaliHeroSection = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  const [email, setEmail] = useState("");
  const Rectangle =
    slice?.primary?.caption_title_dropdown == "rounded rectangle";
  return (
    <section className="space-y-5 sm:space-y-7">
      <p
        className={`text-xs text-purple-1100 -tracking-tightest_5 font-semibold font-manrope  uppercase ${Rectangle ? "bg-purple-2001 rounded-md max-w-fit px-3 py-1.5 " : ""}`}
      >
        {slice?.primary?.caption_title?.text}
      </p>
      <div
        className="text-3.25xl leading-10 sm:text-[60px] sm:leading-[68px] dali_strong font-semibold font-manrope tracking-tightest_7"
        dangerouslySetInnerHTML={{ __html: slice?.primary?.title?.html }}
      />
      <p className="text-base text-gray-600 sm:text-lg tracking-tight_1 sm:max-w-[550px] lg:max-w-full">
        {slice?.primary?.descriptor_text?.text}
      </p>
      {slice?.primary?.show_email_cta ? (
        <SingleEmailForm2
          openModal={toggleModal}
          setEmail={setEmail}
          formHsClass={slice?.primary?.hs_class?.text}
          formId={slice?.primary?.hs_id?.text}
        />
      ) : (
        <CustomButton
          onClick={toggleModal}
          link={slice?.primary?.cta_link?.url}
          buttonClass={`bg-purple-1500 text-white`}
          text={slice?.primary?.cta_text?.text}
        />
      )}
    </section>
  );
};

export const HeroDali = ({ slice }) => {
  return (
    <section className="relative px-4  sm:px-12  lg:px-0 max-w-7xl  lg:max-w-[1440px] xl:ml-[144px] lg:ml-11 lg:mr-[0px] mx-auto 2xl:mx-auto 2xl:max-w-7xl">
      <article className="pt-10 lg:pt-20 2xl:ml-11 2xl:mr-[0px]">
        <div className="flex flex-col items-center md:flex-row">
          <DaliHeroSection slice={slice} />
          <div className="py-10 -mx-4 lg:pl-16 sm:-mx-12 lg:-mx-0 bg_image_dali sm:w-screen lg:w-auto">
            <img
              className="px-4 sm:px-12 lg:px-0"
              src={slice?.primary?.image?.url}
            />
          </div>
        </div>
      </article>
    </section>
  );
};
