import React from "react";

const LeftHeaderArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178"
      height="20"
      viewBox="0 0 178 20"
      fill="none"
    >
      <path
        d="M0.765625 19.9998V0.799805H177.166"
        stroke="#595959"
        stroke-width="1.2"
        stroke-dasharray="2.4 2.4"
      />
    </svg>
  );
};

export default LeftHeaderArrow;
