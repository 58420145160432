import React, { useContext } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import "../../../styles/components/Slices/LogosMonnet.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const LogosMonet = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);

  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article
        className={` lg:mx-11 ${slice?.primary === null ? "py-10 lg:py-20" : "py-10"}`}
      >
        {!slice?.primary === null && (
          <HeadingSection
            data={slice?.primary}
            toggleModal={toggleModal}
            sectionStyle="sm:max-w-[648px] tracking-[-0.45px] md:max-w-[1002px] lg:!pb-10"
            descriptionStyle="lg:text-lg Leading lg:tracking-tighter_1"
            textAreaStyle="gap-4"
            headingStyle="sm:text-3.25xl sm:leading-10 sm:!-tracking-[0.9px]"
          />
        )}
        <div className="grid flex-wrap grid-cols-2 gap-3 sm:gap-0 sm:grid-cols-4 ">
          {slice?.items?.map((value, index) => {
            const content = (
              <div className="flex justify-center items-center min-h-18 max-h-18 lg:max-h-[100px] lg:min-h-[100px] w-full">
                <img
                  className="!object-scale-down max-w-[157px] min-w-[157px] min-h-[49px] max-h-[57px]"
                  src={value?.image?.url}
                  alt={value?.image?.alt}
                />
              </div>
            );
            return (
              <div key={index}>
                {value.logo_link?.url ? (
                  <a
                    href={value.logo_link?.url}
                    target="_blank"
                    className="hover:!bg-[#ffff] cursor-pointer image_bg_hover"
                    rel="noopener noreferrer"
                  >
                    {content}
                  </a>
                ) : (
                  content
                )}
              </div>
            );
          })}
        </div>
      </article>
    </section>
  );
};
