import React, { useState } from "react";

const FeatureDaliCard = ({ data, index }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  return (
    <div
      key={index}
      onMouseEnter={() => setHoveredCardIndex(index)}
      onMouseLeave={() => setHoveredCardIndex(null)}
      className="flex flex-col min-w-[212px] sm:min-w-[300px] md:min-w-[368px] feature_dali_width group"
    >
      <img
        src={hoveredCardIndex !== null ? data?.hover_icon?.url : data?.icon?.url}
        width={30}
        height={30}
        className="mb-3.5"
        alt="img"
      />
      
      <div className="font-manrope text-xl font-semibold  text-gray-2700 tracking-tightest max-w-[212px] min-h-[84px] sm:min-h-14 sm:max-w-[328px] group-hover:text-purple-1100">
        {data?.title?.text}
      </div>
      <div className="text-base font-normal text-gray-600 group-hover:text-gray-2500 font-inter tracking-tight_1 mt-2.5">
        {data?.description?.text}
      </div>
      
    </div>
  );
};

export default FeatureDaliCard;
