import { Link } from "gatsby-link";
import React, { useState } from "react";
import PreviousIcon from "../../assets/icons/BlogDetailPage/previousIcon";
import NextBlogIcon from "../../assets/icons/BlogDetailPage/nextIcon";
import TooltipCard from "../../components/V3/Blog/TooltipCard/index";
import BlogCard from "../V3/Blog/BlogCard";
import CustomerCard from "../customerLp/CustomerCard";

const PreviousNextBlog = ({ blogs, location }) => {
  const [target, setTarget] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const pathname = location?.pathname;
  return (
    <div className="flex flex-col items-center justify-center gap-4 mx-4 sm:flex-row sm:mx-12 lg:mx-6 xl:mx-0">
      {blogs.slice(0, 2).map((blog, index) => (
        <Link
          to={`${pathname.startsWith("/blog/") ? "/blog" : "/customers"}/${blog?.node?.uid}/`}
          key={index}
          className="p-5 sm:p-10 border border-solid border-gray-1400 hover:bg-white bg-white lg:bg-gray-2800 rounded-[14px] blog_card_rotating_border cursor-pointer group max-w-[564px] w-full sm:w-1/2"
          // onMouseEnter={() => {
          //   setTarget("Index");
          //   setActiveIndex(index);
          // }}
          // onMouseLeave={() => setTarget(null)}
        >
          <div className="flex items-center justify-between gap-6">
            {index === 0 && (
              <div className="w-6">
                <PreviousIcon />
              </div>
            )}
            <div className=" flex flex-col gap-2.5">
              <p
                className={`text-xs sm:text-sm font-medium leading-5 uppercase text-purple-1500 lg:text-gray-600 lg:group-hover:text-purple-1500 font-manrope sm:font-inter tracking-widest_2 ${index === 0 ? "text-right" : "text-left"}`}
              >
                {index === 0
                  ? pathname.startsWith("/blog/")
                    ? "Previous blog"
                    : "Previous Case study"
                  : pathname.startsWith("/blog/")
                    ? "Next blog"
                    : "Next Case study"}
              </p>
              <h1
                className={`text-lg lg:text-xl font-semibold leading-7 text-gray-2700 font-manrope tracking-tightest min-h-14 line-clamp-2 ${index === 0 ? "text-right" : "text-left"}`}
              >
                {blog?.node?.data?.title?.text}
              </h1>
            </div>
            {index !== 0 && (
              <div className="w-6">
                <NextBlogIcon />
              </div>
            )}
          </div>
        </Link>
      ))}
      {target && (
        <TooltipCard className={target} offset={{ x: -184, y: 30 }}>
          <BlogCard
            searchText={""}
            location={location}
            blog={blogs[activeIndex]}
            containerClasses="max-w-[368px]"
          />
        </TooltipCard>
      )}
    </div>
  );
};

export default PreviousNextBlog;
