import React, { useContext } from "react";
import CustomButton from "../../CustomButton";
import ModalContext from "../../../Contexts/openModal";

const FooterBanner = ({ slice }) => {
  const { toggleModal } = useContext(ModalContext);
  return (
    <article className="relative bg-purple-2300">
      <section className="relative mx-5 max-w-7xl xl:px-16 xl:mx-auto sm:mx-6 ">
        <section className="max-w-lg mx-auto text-center text-gray-2800 lg:max-w-3xl lg:px-8 py-36">
          <h3 className="font-bold font-manrope lg:text-4.5xl sm:text-3.25xl text-2xl lg:mb-6 sm:mb-7 mb-5">
            {slice?.primary?.cta_heading?.text}
          </h3>
          <p className="mb-8 text-sm font-inter sm:text-lg sm:mb-7">
            {slice?.primary?.cta_description?.text}
          </p>
          <CustomButton
            onClick={toggleModal}
            text={slice?.primary?.cta_text?.text}
            buttonClass="text-white dicom-btn mx-auto"
          />
        </section>
      </section>
    </article>
  );
};
export default FooterBanner;
