import React from "react";

export const BottomToTopArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="60"
      viewBox="0 0 7 60"
      fill="none"
      className="absolute bottom-[175px] left-[50px] sm:hidden "
    >
      <path
        d="M3.24805 0L0.361296 5L6.1348 5L3.24805 0ZM3.74805 60L3.74805 59L2.74805 59L2.74805 60L3.74805 60ZM3.74805 57L3.74805 55L2.74805 55L2.74805 57L3.74805 57ZM3.74805 53L3.74805 51L2.74805 51L2.74805 53L3.74805 53ZM3.74805 49L3.74805 47L2.74805 47L2.74805 49L3.74805 49ZM3.74805 45L3.74805 43L2.74805 43L2.74805 45L3.74805 45ZM3.74805 41L3.74805 39L2.74805 39L2.74805 41L3.74805 41ZM3.74805 37L3.74805 35L2.74805 35L2.74805 37L3.74805 37ZM3.74805 33L3.74805 31L2.74805 31L2.74805 33L3.74805 33ZM3.74805 29L3.74805 27L2.74805 27L2.74805 29L3.74805 29ZM3.74805 25L3.74805 23L2.74805 23L2.74805 25L3.74805 25ZM3.74805 21L3.74805 19L2.74805 19L2.74805 21L3.74805 21ZM3.74805 17L3.74805 15L2.74805 15L2.74805 17L3.74805 17ZM3.74805 13L3.74805 11L2.74805 11L2.74805 13L3.74805 13ZM3.74805 9L3.74805 7L2.74805 7L2.74805 9L3.74805 9ZM3.74805 5L3.74805 3L2.74805 3L2.74805 5L3.74805 5Z"
        fill="#595959"
      />
    </svg>
  );
};
