import React from "react";

export const LeftToRightArrow = ({styleClass}) => (
  <svg
  width="147"
  height="80"
  viewBox="0 0 147 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  className={styleClass}
>
  <path
    d="M147 40L142 37.1132V42.8868L147 40ZM0 40.5H0.993243V39.5H0V40.5ZM2.97973 40.5H4.96622V39.5H2.97973V40.5ZM6.9527 40.5H8.93919V39.5H6.9527V40.5ZM10.9257 40.5H12.9122V39.5H10.9257V40.5ZM14.8986 40.5H16.8851V39.5H14.8986V40.5ZM18.8716 40.5H20.8581V39.5H18.8716V40.5ZM22.8446 40.5H24.8311V39.5H22.8446V40.5ZM26.8176 40.5H28.8041V39.5H26.8176V40.5ZM30.7905 40.5H32.777V39.5H30.7905V40.5ZM34.7635 40.5H36.75V39.5H34.7635V40.5ZM38.7365 40.5H40.723V39.5H38.7365V40.5ZM42.7095 40.5H44.696V39.5H42.7095V40.5ZM46.6824 40.5H48.6689V39.5H46.6824V40.5ZM50.6554 40.5H52.6419V39.5H50.6554V40.5ZM54.6284 40.5H56.6149V39.5H54.6284V40.5ZM58.6014 40.5H60.5879V39.5H58.6014V40.5ZM62.5743 40.5H64.5608V39.5H62.5743V40.5ZM66.5473 40.5H68.5338V39.5H66.5473V40.5ZM70.5203 40.5H72.5068V39.5H70.5203V40.5ZM74.4933 40.5H76.4798V39.5H74.4933V40.5ZM78.4663 40.5H80.4527V39.5H78.4663V40.5ZM82.4392 40.5H84.4257V39.5H82.4392V40.5ZM86.4122 40.5H88.3987V39.5H86.4122V40.5ZM90.3852 40.5H92.3717V39.5H90.3852V40.5ZM94.3582 40.5H96.3446V39.5H94.3582V40.5ZM98.3311 40.5H100.318V39.5H98.3311V40.5ZM102.304 40.5H104.291V39.5H102.304V40.5ZM106.277 40.5H108.264V39.5H106.277V40.5ZM110.25 40.5H112.237V39.5H110.25V40.5ZM114.223 40.5H116.21V39.5H114.223V40.5ZM118.196 40.5H120.183V39.5H118.196V40.5ZM122.169 40.5H124.155V39.5H122.169V40.5ZM126.142 40.5H128.128V39.5H126.142V40.5ZM130.115 40.5H132.101V39.5H130.115V40.5ZM134.088 40.5H136.074V39.5H134.088V40.5ZM138.061 40.5H140.047V39.5H138.061V40.5ZM142.034 40.5H144.02V39.5H142.034V40.5Z"
    fill="#595959"
  />
</svg>
  );
