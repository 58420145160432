import React from "react";
import EncordLogo from "../assets/images/EncordLogo.svg";
import SignUpForm from "../utilities/SignUpForm";
import CustomLink from "../components/CustomLink";
import { industries } from "./SolutionsHeaderButton";
import { Link } from "gatsby-link";
import { GitHubIcon } from "../assets/icons/gitHubIcon";
import { TwitterIcon } from "../assets/icons/twitterIcon";
import { GitHubWhiteIcon } from "../assets/icons/gitHubWhiteIcon";
import { TwitterWhiteIcon } from "../assets/icons/twitterWhiteIcon";
import { useLocation } from "@reach/router";

const products = [
  {
    text: "Image",
    href: "/image/",
  },
  {
    text: "Video",
    href: "/video/",
  },
  {
    text: "DICOM",
    href: "/dicom/",
  },
  {
    text: "SAR",
    href: "/sar/",
  },
  {
    text: "Automation",
    href: "/automate/",
  },
  {
    text: "API & Python SDK",
    href: "/api/",
  },
  {
    text: "Quality Assessment",
    href: "/quality/",
  },
  {
    text: "Encord Active",
    href: "/active/",
  },
];

const company = [
  {
    text: "About",
    href: "/about-us/",
  },
  {
    text: "Careers",
    href: "/careers/",
  },
  {
    text: "Customers",
    href: "/customers/",
  },
  {
    text: "Contact Us",
    href: "/contact-us/",
  },
  {
    text: "Documentation",
    href: "https://docs.encord.com/",
  },
  {
    text: "Glossary",
    href: "/glossary/",
  },
  {
    text: "Blog",
    href: "/blog/",
  },
  {
    text: "Press",
    href: "/press/",
  },
  {
    text: "Pricing",
    href: "/pricing/",
  },
  {
    text: "Security",
    href: "/security-at-encord/",
  },
];

export const footerTheme = {
  default: {
    background: "bg-white",
    textColor: "text-gray-600",
    hoverTextColor: "text-gray-900",
    groupHeaderTextColor: "text-gray-2700",
    gitHubLogo: <GitHubIcon />,
    twitterLogo: <TwitterIcon />,
    borderColor: "border-gray-200",
    isDivider: false,
    subFormContainerCalsses:
      "relative flex items-center max-w-xs rounded-full overflow-hidden shadow-card border-none",
    subFormInputCalsses:
      "w-full px-3 py-2.5 pr-12 text-sm lg:text-base text-gray-800 border-none rounded-none form-input focus:ring-white focus:border-white placeholder:text-gray-2200",
    subBtnClasses: "absolute inset-0 left-auto bg-purple-1500 px-2",
    subBtnIconClasses: "flex-shrink-0 w-3 h-3 mx-3 text-purple-600 fill-white",
  },

  purple: {
    background: "bg-[#1d0642]",
    textColor: "text-gray-1600",
    hoverTextColor: "text-gray-1600",
    groupHeaderTextColor: "text-gray-2800",
    gitHubLogo: <GitHubWhiteIcon />,
    twitterLogo: <TwitterWhiteIcon />,
    borderColor: "border-t-[#1d0642]",
    isDivider: true,
    subFormContainerCalsses:
      "relative flex items-center max-w-xs rounded-full overflow-hidden",
    subFormInputCalsses:
      "w-full px-3 py-2 pr-12 text-sm text-[#B9ACCE] border-[#85789A] bg-transparent rounded-full shadow-sm form-input focus:ring-[#85789A] focus:border-[#85789A] placeholder:text-[#B9ACCE]",
    subBtnClasses: "absolute inset-0 left-auto backgrooundSubscriberBtn",
    subBtnIconClasses: "flex-shrink-0 w-9 h-3 mx-3 text-white fill-current",
  },
  purpleWithoutOpacity: {
    background: "bg-[#1d0642]",
    textColor: "text-gray-1600",
    hoverTextColor: "text-gray-1600",
    groupHeaderTextColor: "text-gray-2800",
    gitHubLogo: <GitHubWhiteIcon />,
    twitterLogo: <TwitterWhiteIcon />,
    borderColor: "border-t-[#1d0642]",
    isDivider: true,
    subFormContainerCalsses:
      "relative flex items-center max-w-xs rounded-full overflow-hidden",
    subFormInputCalsses:
      "w-full px-3 py-2 pr-12 text-sm text-[#B9ACCE] border-[#85789A] bg-transparent rounded-full shadow-sm form-input focus:ring-[#85789A] focus:border-[#85789A] placeholder:text-[#B9ACCE]",
    subBtnClasses: "absolute inset-0 left-auto backgrooundSubscriberBtn",
    subBtnIconClasses: "flex-shrink-0 w-9 h-3 mx-3 text-white fill-current",
  },
  newDefault: {
    background: "bg-gray-2800",
    textColor: "text-gray-600",
    hoverTextColor: "text-gray-900",
    groupHeaderTextColor: "text-gray-2700",
    gitHubLogo: <GitHubIcon />,
    twitterLogo: <TwitterIcon />,
    borderColor: "border-gray-200",
    isDivider: false,
    subFormContainerCalsses:
      "relative flex items-center max-w-xs rounded-full overflow-hidden shadow-card border-none",
    subFormInputCalsses:
      "w-full px-3 py-2.5 pr-12 text-sm lg:text-base text-gray-800 border-none rounded-none form-input focus:ring-white focus:border-white placeholder:text-gray-2200",
    subBtnClasses: "absolute inset-0 left-auto bg-purple-1500 px-2",
    subBtnIconClasses: "flex-shrink-0 w-3 h-3 mx-3 text-purple-600 fill-white",
  },
  transparent: {
    background: "bg-gray-2800",
    textColor: "text-gray-600",
    hoverTextColor: "text-gray-900",
    groupHeaderTextColor: "text-gray-2700",
    gitHubLogo: <GitHubIcon />,
    twitterLogo: <TwitterIcon />,
    borderColor: "border-gray-200",
    isDivider: false,
    subFormContainerCalsses:
      "relative flex items-center max-w-xs rounded-full overflow-hidden shadow-card border-none",
    subFormInputCalsses:
      "w-full px-3 py-2.5 pr-12 text-sm lg:text-base text-gray-800 border-none rounded-none form-input focus:ring-white focus:border-white placeholder:text-gray-2200",
    subBtnClasses: "absolute inset-0 left-auto bg-purple-1500 px-2",
    subBtnIconClasses: "flex-shrink-0 w-3 h-3 mx-3 text-purple-600 fill-white",
  },
};

const Footer = ({ theme = "default", isStickyCTA }) => {
  const location = useLocation();
  const careerPage = location.pathname === "/careers/";
  return (
    <footer className={`${footerTheme[theme].background}`}>
      <div
        className={`max-w-6xl mx-auto px-4  ${careerPage ? " pt-10 lg:pt-20" : ""} sm:px-12 lg:px-6 ${isStickyCTA && "mb-14"}`}
      >
        {/* Top area: Blocks */}
        <div
          className={`grid sm:grid-cols-12 gap-5 pb-4 pt-8 md:pt-12 border-t ${footerTheme[theme].borderColor}`}
        >
          {/* 1st block */}
          <div className="flex flex-col justify-between h-full sm:col-span-12 lg:col-span-3">
            <div>
              <div className="mb-6">
                {/* Logo */}
                <CustomLink
                  to="/"
                  className="inline-block"
                  aria-label="encord logo"
                >
                  <EncordLogo width={64} />
                </CustomLink>
              </div>
              <div
                className={`text-xs ${footerTheme[theme].groupHeaderTextColor}`}
              >
                <CustomLink
                  to="/terms/"
                  className={`${footerTheme[theme].groupHeaderTextColor} hover:${footerTheme[theme].hoverTextColor} hover:underline transition duration-150 ease-in-out`}
                >
                  Terms
                </CustomLink>{" "}
                ·{" "}
                <CustomLink
                  to="/privacy/"
                  className={`${footerTheme[theme].groupHeaderTextColor}  hover:${footerTheme[theme].hoverTextColor} hover:underline transition duration-150 ease-in-out`}
                >
                  Privacy Policy
                </CustomLink>
              </div>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-4 md:col-span-3 lg:col-span-2">
            <h6
              className={`${footerTheme[theme].groupHeaderTextColor} font-medium mb-5 flex items-center h-11 tracking-tighter_1`}
            >
              Platform
            </h6>
            <ul className="text-sm tracking-tighter_3">
              {products?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="mb-1.5 h-7.5 flex items-center text-base"
                  >
                    <CustomLink
                      to={item?.href}
                      className={`${footerTheme[theme].textColor}  hover:${footerTheme[theme].hoverTextColor} transition duration-150 ease-in-out`}
                    >
                      {item?.text}
                    </CustomLink>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-4 md:col-span-3 lg:col-span-2">
            <h6
              className={`${footerTheme[theme].groupHeaderTextColor} font-medium mb-5 flex items-center h-11 tracking-tighter_1`}
            >
              Industries
            </h6>
            <ul className="text-sm tracking-tighter_3">
              {industries?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="mb-1.5 min-h-[30px] flex items-center text-base"
                  >
                    <CustomLink
                      to={item?.href}
                      className={`${footerTheme[theme].textColor}  hover:${footerTheme[theme].hoverTextColor} transition duration-150 ease-in-out`}
                    >
                      {item?.text}
                    </CustomLink>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-4 md:col-span-3 lg:col-span-2">
            <h6
              className={`${footerTheme[theme].groupHeaderTextColor} font-medium mb-5 flex items-center h-11 tracking-tighter_1`}
            >
              Company
            </h6>
            <ul className="text-sm tracking-tighter_3">
              {company?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="mb-1.5 h-7.5 flex items-center text-base"
                  >
                    {item?.href == "https://docs.encord.com/" ? (
                      <Link
                        to={item?.href}
                        className={`${footerTheme[theme].textColor}  hover:${footerTheme[theme].hoverTextColor} transition duration-150 ease-in-out`}
                      >
                        {item?.text}
                      </Link>
                    ) : (
                      <CustomLink
                        to={item?.href}
                        className={`${footerTheme[theme].textColor}  hover:${footerTheme[theme].hoverTextColor} transition duration-150 ease-in-out`}
                      >
                        {item?.text}
                      </CustomLink>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* 5th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3 sm:max-w-[243px]">
            <h6
              className={`${footerTheme[theme].groupHeaderTextColor} font-medium mb-5 flex items-center h-11 tracking-tighter_1`}
            >
              Subscribe
            </h6>
            <p
              className={`text-base ${footerTheme[theme].textColor} tracking-tighter_3  mb-1.5 flex items-start h-14`}
            >
              Get occasional product updates and tutorials to your inbox.
            </p>
            <SignUpForm subscribe theme={theme} />
          </div>
        </div>

        <div className="flex flex-row gap-4 mb-8">
          <a
            className="w-20"
            href=" https://drata.com/product "
            target="_blank"
          >
            <img
              src="https://cdn.drata.com/badge/soc2-dark.png"
              height={140}
              width={140}
              alt="Soc2 badge"
              loading="lazy"
            />
          </a>
          <div className="w-20">
            <img
              src={
                "https://images.prismic.io/encord/d5a5f02e-d8df-49c2-9413-5633a8e75e7d_soc2-certificate.png?auto=compress,format"
              }
              alt="Soc2 certificate"
              height={140}
              width={140}
              loading="lazy"
            />
          </div>

          <img
            className="w-20 h-auto ml-auto"
            src={
              "https://encord.cdn.prismic.io/encord/ZoZ1tR5LeNNTwyYw_g22024.svg"
            }
            alt="G2"
            loading="lazy"
            width={0}
            height={0}
          />
        </div>

        {/* Bottom area */}
        <div
          className={`md:flex md:items-center md:justify-between py-2 md:py-2 `}
        >
          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <a
                href="https://twitter.com/encord_team"
                target="_blank"
                rel="noopener noreferrer"
                className={`flex justify-center items-center ${footerTheme[theme].textColor}  hover:${footerTheme[theme].hoverTextColor}  hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out`}
                aria-label="Twitter"
              >
                {footerTheme[theme].twitterLogo}
              </a>
            </li>
            <li className="ml-4">
              <a
                href="https://github.com/encord-team"
                target="_blank"
                rel="noopener noreferrer"
                className={`flex justify-center items-center ${footerTheme[theme].textColor}  hover:${footerTheme[theme].hoverTextColor}  hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out`}
                aria-label="Github"
              >
                {footerTheme[theme].gitHubLogo}
              </a>
            </li>
          </ul>

          {/* Copyrights note */}
          <div
            className={`text-base ${footerTheme[theme].groupHeaderTextColor} sm:mt-12 lg:mt-0  mr-4`}
          >
            &copy; 2023 Encord. All rights reserved.
          </div>
        </div>

        {/* Address area */}
        <div className="py-4 md:flex md:items-center md:justify-between lg:items-start lg:mt-2">
          <div
            className={`text-xs ${footerTheme[theme].groupHeaderTextColor} pt-2.5 lg:pt-0  mr-4`}
          >
            © Cord Technologies, Inc.
            <br />© Cord Technologies Limited
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
