import React, { useContext } from "react";
import "../../../styles/components/Slices/diagramMonet.scss";
import HeadingSection from "../../Pieces/HeadingSection";
import ModalContext from "../../../Contexts/openModal";
import DiagramMonetCard from "../../Pieces/DiagramMonetCard";
import RawDataSection from "../../Pieces/RawDataSection";
import AiModalSection from "../../Pieces/AiModalSection";
import logo from "../../../assets/Encord logo.png";
import RightToLeftGradiantArrow from "../../../assets/icons/DiagramMonetIcons/RightToLeftGradiantArrow";
import LeftToUpArrow from "../../../assets/icons/DiagramMonetIcons/LeftToUpArrow";
import UptoDownGradientArrow from "../../../assets/icons/DiagramMonetIcons/UptoDownGradientArrow";
import { LeftToRightArrow } from "../../../assets/icons/DiagramMonetIcons/LeftToRightArrow";
import LeftToRightTabletArrow from "../../../assets/icons/DiagramMonetIcons/LeftToRightTabletArrow";
import RightToLeftTabletArrow from "../../../assets/icons/DiagramMonetIcons/RightToLeftTabletArrow";
import BottomToTopRightArrow from "../../../assets/icons/DiagramMonetIcons/BottomToTopRightArrow";
import LeftHeaderArrow from "../../../assets/icons/DiagramMonetIcons/LeftHeaderArrow";
import RightHeaderArrow from "../../../assets/icons/DiagramMonetIcons/RightHeaderArrow";
import BottomToTopRightArrowMobile from "../../../assets/icons/DiagramMonetIcons/BottomToTopRightArrowMobile";
import UptoDownRightArrow from "../../../assets/icons/DiagramMonetIcons/UptoDownRightArrow";
import ToptoBottomrightArrow from "../../../assets/icons/DiagramMonetIcons/ToptoBottomrightArrow";
import ToptoBottomLeft from "../../../assets/icons/DiagramMonetIcons/ToptoBottomLeft";

const DiagramMonet = ({ slice, location }) => {
  const { toggleModal } = useContext(ModalContext);

  const RawData = [
    {
      Icon: slice?.primary?.raw_data_image_1?.url,
    },
    {
      Icon: slice?.primary?.raw_data_image_2?.url,
    },
    {
      Icon: slice?.primary?.raw_data_image_3?.url,
    },
    {
      Icon: slice?.primary?.raw_data_image_4?.url,
    },
  ];
  const AiData = [
    {
      Icon: slice?.primary?.ai_model_image_1?.url,
    },
    {
      Icon: slice?.primary?.ai_model_image_2?.url,
    },
    {
      Icon: slice?.primary?.ai_model_image_3?.url,
    },
    {
      Icon: slice?.primary?.ai_model_image_4?.url,
    },
  ];

  return (
    <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
      <article className="relative py-10 lg:py-20 lg:mx-11 ">
        <HeadingSection
          data={slice?.primary}
          toggleModal={toggleModal}
          sectionStyle="sm:max-w-[648px] tracking-[-0.45px] md:max-w-[1002px] lg:!pb-10"
          descriptionStyle="lg:text-lg Leading lg:tracking-tighter_1"
          textAreaStyle="gap-4"
          headingStyle="sm:text-3.25xl sm:leading-10 sm:!-tracking-[0.9px]"
        />
        <div className="relative hidden sm:flex items-center justify-center w-[42%] mx-auto gap-3.5">
          <LeftHeaderArrow />
          <div className="flex flex-col items-center mb-5 ">
            <img src={logo} className="object-contain h-6" />
          </div>
          <RightHeaderArrow />
        </div>

        <div className="relative flex flex-col grid-cols-1 gap-8 mx-8 sm:mx-0 sm:gap-4 sm:grid lg:px-4 sm:grid-cols-10 lg:grid-cols-12">
          <div className="flex col-span-2 lg:col-span-2">
            <RawDataSection
              rawData={RawData}
              rawHeading={slice?.primary?.raw_data_title?.text}
            />
            <ToptoBottomrightArrow />
            <LeftToRightTabletArrow styleClass="absolute top-28 left-[91px] hidden sm:block lg:hidden" />
            <BottomToTopRightArrow styleClass="absolute bottom-24 left-[113px] hidden sm:block lg:hidden" />
            <UptoDownRightArrow styleClass="absolute bottom-24 left-[113px] block hidden sm:hidden" />
            <BottomToTopRightArrowMobile styleClass="absolute bottom-[300px] left-[-12px] sm:hidden" />
          </div>
          <div className="flex flex-col col-span-6 px-3 sm:px-7 lg:px-12 lg:grid grid-cols-subgrid span-rows-2 py-[18px] sm:py-7 lg:col-span-8 center_container_background">
            <img
              src={logo}
              className="self-end object-contain h-6 mb-5 sm:hidden"
            />
            <div className="flex flex-col grid-flow-col-dense lg:items-center lg:col-span-8 lg:grid">
              <DiagramMonetCard
                showArrow={"index0"}
                data={slice?.items[0]}
                styleClass={"col-span-3 lg:!max-h-fit "}
              />

              <RightToLeftGradiantArrow />

              <UptoDownGradientArrow />
              <DiagramMonetCard
                showArrow={"index1"}
                data={slice?.items[1]}
                styleClass={"col-span-3 w-full"}
              />
            </div>
            <div className="grid-flow-col-dense col-span-8 lg:grid ">
              <div className="col-span-5" />
              <LeftToUpArrow />

              <DiagramMonetCard
                data={slice?.items[2]}
                showArrow={"index2"}
                styleClass={"col-span-2  mt-3.5 relative "}
              />
            </div>
          </div>

          <div className="flex justify-end col-span-2 lg:col-span-2 sm:justify-start sm:justify-self-end">
            <LeftToRightArrow styleClass="absolute top-[60px] right-[124px] hidden lg:block" />
            <LeftToRightTabletArrow styleClass="absolute bottom-[55%] right-[107px] sm:block lg:hidden" />
            <RightToLeftTabletArrow styleCLass="absolute bottom-[104px] right-[94px] hidden sm:block lg:hidden" />
            <ToptoBottomLeft />
            <AiModalSection
              modalHeading={slice?.primary?.ai_model_title?.text}
              aiData={AiData}
              modelPredictionIcon={slice?.primary?.model_prediction_icon?.url}
              modelPredictionTitle={
                slice?.primary?.model_prediction_title?.text
              }
            />
          </div>
        </div>
      </article>
    </section>
  );
};
export default DiagramMonet;
