import React from "react";

const RightToLeftTabletArrow = ({ styleCLass }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="6"
      viewBox="0 0 72 6"
      fill="none"
      className={`${styleCLass}`}
    >
      <path
        d="M0 3L5 5.88675V0.113249L0 3ZM72 2.5H71V3.5H72V2.5ZM69 2.5H67V3.5L69 3.5V2.5ZM65 2.5H63V3.5H65V2.5ZM61 2.5L59 2.5V3.5L61 3.5V2.5ZM57 2.5L55 2.5V3.5L57 3.5V2.5ZM53 2.5L51 2.5V3.5L53 3.5V2.5ZM49 2.5L47 2.5V3.5L49 3.5V2.5ZM45 2.5H43V3.5H45V2.5ZM41 2.5L39 2.5V3.5L41 3.5V2.5ZM37 2.5H35V3.5H37V2.5ZM33 2.5H31V3.5H33V2.5ZM29 2.5H27V3.5H29V2.5ZM25 2.5L23 2.5V3.5L25 3.5V2.5ZM21 2.5L19 2.5V3.5H21V2.5ZM17 2.5L15 2.5V3.5L17 3.5V2.5ZM13 2.5L11 2.5V3.5L13 3.5V2.5ZM9 2.5L7 2.5V3.5L9 3.5V2.5ZM5 2.5L3 2.5V3.5L5 3.5V2.5Z"
        fill="#595959"
      />
    </svg>
  );
};

export default RightToLeftTabletArrow;
