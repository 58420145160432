import React, { useContext } from "react";
import HeadingSection from "../../Pieces/HeadingSection";
import FeatureDaliCard from "../../Pieces/FeatureDaliCard";
import "../../../styles/components/Slices/FeatureDali.scss";
import ModalContext from "../../../Contexts/openModal";

const FeatureDali = ({ slice }) => {
  const {toggleModal } = useContext(ModalContext);
  return (
    <section className={`relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl`}>
      <article className="py-10 lg:py-20 lg:mx-11">
        <HeadingSection data={slice?.primary} toggleModal={toggleModal} />
        <section className="flex gap-4 overflow-x-auto sm:gap-6 hideScrollBar scroll-smooth cs_slide_opacity_right ">
          {slice?.items?.map((data, index) => (
            <FeatureDaliCard data={data} index={index} />
          ))}
        </section>
      </article>
    </section>
  );
};

export default FeatureDali;
