import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import "../../../styles/components/Slices/FeatureMonet.scss";

const InteractionCard = ({ data, index }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 1023 });
  return (
    <div
      key={index}
      onMouseEnter={() => setHoveredCardIndex(index)}
      onMouseLeave={() => setHoveredCardIndex(null)}
      className="flex flex-col gap-2 lg:flex-row"
    >
      <img
        src={
          isDesktop
            ? hoveredCardIndex !== null
              ? data?.hover_icon?.url
              : data?.icon?.url
            : data?.hover_icon?.url
        }
        width={22}
        height={28}
        className="h-7 w-[22px]"
        alt="img"
      />

      <p className="flex flex-col gap-2 text-sm font-normal text-gray-600 sm:text-base lg:inline lg:flex-row lg:gap-0 font-inter tracking-tight_1 max-w-[338px]">
        <strong className="text-lg font-semibold text-gray-2700 font-manrope tracking-tightest title_height lg:pr-1">
          {data?.title?.text}
        </strong>
        {data?.description?.text}
      </p>
    </div>
  );
};

export default InteractionCard;
