import React from "react";
import "../../../styles/components/Pieces/HeroSupportingAssetMonetMarquee.scss";
import { LOGOS_DURATION } from "../../../constants";
const HeroSupportingAssetMonetMarquee = ({ data, duration, leftToRight }) => {
  return (
    <div className="py-10 marquee-list">
      <InfiniteLoopSlider
        duration={duration || LOGOS_DURATION}
        reverse={leftToRight}
      >
        {data?.map((data, index) => (
          <img
            key={index}
            src={data?.gallery_image?.url}
            alt={data?.gallery_image?.url || "img"}
            width={0}
            height={0}
            className="object-cover image_style"
          />
        ))}
      </InfiniteLoopSlider>

      <div className="fade" />
    </div>
  );
};

export default HeroSupportingAssetMonetMarquee;

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="marquee-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="gap-6 lg:gap-16 inner-marquee">
        {children}
        {children}
      </div>
    </div>
  );
};
