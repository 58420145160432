import React, { useEffect, useRef, useState } from "react";

/**
 * VideoComponent is a functional component that renders an HTML5 video element with specified attributes.
 *
 * @component
 * @param {object} props - The component's properties.
 * @param {React.ReactNode} [props.children] - The content to be displayed within the video element.
 * @param {string} [props.className] - A CSS class name to apply to the video element.
 * @param {object} [props...rest] - Additional attributes to be passed to the video element.
 * @returns {React.ReactNode} The rendered video component.
 */
function VideoComponent({ children, ...rest }) {
  const [isMounted, setIsMounted] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && isMounted) {
      videoElement.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    }

    return () => {
      setIsMounted(false);
      if (videoElement) {
        videoElement.pause();
      }
    };
  }, [isMounted]);

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      loop
      playsInline
      preload="auto"
      {...rest}
    >
      {children}
      Your browser does not support the video tag.
    </video>
  );
}

export default VideoComponent;
