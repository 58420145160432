import React from "react";

const RawDataSection = ({ rawData, rawHeading }) => {
  return (
    <div className="flex flex-col sm:items-center gap-2.5 z-20 sm:pt-7 lg:pt-0">
      <p className="w-full max-w-fit px-3 py-1 text-sm font-medium text-center rounded-[60px] bg-gray-1500 font-inter -tracking-wider">
        {rawHeading}
      </p>
      <div className="max-w-[220px] sm:max-w-fit grid grid-cols-4 sm:grid-cols-2 gap-5 sm:gap-4 p-3 sm:p-2.5 rounded bg-gray-1500 ">
        {rawData?.map((value, index) => {
          return (
            <img key={index} src={value?.Icon} className="w-auto h-auto" />
          );
        })}
      </div>
    </div>
  );
};

export default RawDataSection;
