import React from "react";

const RightToLeftArrow = ({styleClass}) => {
  return (
    <svg
      width="127"
      height="80"
      viewBox="0 0 127 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${styleClass}`}
    >
      <path
        d="M0 40L5 42.8868V37.1132L0 40ZM127 39.5H126.008V40.5H127V39.5ZM124.023 39.5H122.039V40.5H124.023V39.5ZM120.055 39.5H118.07V40.5H120.055V39.5ZM116.086 39.5H114.102V40.5H116.086V39.5ZM112.117 39.5H110.133V40.5H112.117V39.5ZM108.148 39.5H106.164V40.5H108.148V39.5ZM104.18 39.5H102.195V40.5H104.18V39.5ZM100.211 39.5H98.2266V40.5H100.211V39.5ZM96.2422 39.5H94.2578V40.5H96.2422V39.5ZM92.2734 39.5H90.2891V40.5H92.2734V39.5ZM88.3047 39.5H86.3203V40.5H88.3047V39.5ZM84.3359 39.5H82.3516V40.5H84.3359V39.5ZM80.3672 39.5H78.3828V40.5H80.3672V39.5ZM76.3984 39.5H74.4141V40.5H76.3984V39.5ZM72.4297 39.5H70.4453V40.5H72.4297V39.5ZM68.4609 39.5H66.4766V40.5H68.4609V39.5ZM64.4922 39.5H62.5078V40.5H64.4922V39.5ZM60.5234 39.5H58.5391V40.5H60.5234V39.5ZM56.5547 39.5H54.5703V40.5H56.5547V39.5ZM52.5859 39.5H50.6016V40.5H52.5859V39.5ZM48.6172 39.5H46.6328V40.5H48.6172V39.5ZM44.6484 39.5H42.6641V40.5H44.6484V39.5ZM40.6797 39.5L38.6953 39.5V40.5L40.6797 40.5V39.5ZM36.7109 39.5H34.7266V40.5H36.7109V39.5ZM32.7422 39.5H30.7578V40.5H32.7422V39.5ZM28.7734 39.5H26.7891V40.5H28.7734V39.5ZM24.8047 39.5H22.8203V40.5H24.8047V39.5ZM20.8359 39.5H18.8516V40.5H20.8359V39.5ZM16.8672 39.5H14.8828V40.5H16.8672V39.5ZM12.8984 39.5H10.9141V40.5H12.8984V39.5ZM8.92969 39.5H6.94531V40.5H8.92969V39.5ZM4.96094 39.5H2.97656V40.5H4.96094V39.5Z"
        fill="#595959"
      />
    </svg>
  );
};

export default RightToLeftArrow;
